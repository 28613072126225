import styled, { css } from 'styled-components';
import { MEDIA, SPACING } from 'clark-styles';

const { S_05, S_1, S_2, S_4, S_6 } = SPACING;

const xs = css`
  margin-bottom: ${S_05};
`;

const sm = css`
  margin-bottom: ${S_1};
`;

const md = css`
  margin-bottom: ${S_2};
`;

const lg = css`
  margin-bottom: ${S_4};
`;

const xl = css`
  margin-bottom: ${S_6};
`;

const xxl = css`
  margin-bottom: ${S_6};
  ${MEDIA.large`
    margin-bottom: 12.5rem;
  `};
`;

const space = {
  XS: () => xs,
  SM: () => sm,
  MD: () => md,
  LG: () => lg,
  XL: () => xl,
  XXL: () => xxl,
};

const StyledWrapper = styled.div`
  ${({ spaceBelow }) => space[spaceBelow]};
`;

export default StyledWrapper;
