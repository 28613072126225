import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Grid, Row, Col, SPACING } from 'clark-styles';

import HeadingElement from '../heading-element';
import SectionWrapper from '../section-wrapper';

import {
  StyledGrid,
  StyledRow,
  Summary,
  Testimonial,
  TestimonialCite,
  TestimonialCol,
  TestimonialImage,
} from './styles';

const { S_025 } = SPACING;

// wrapping at 4 testimonials
const colSize = (total, index) => {
  const isInLastLine = index >= total - (total % 4);
  return isInLastLine ? 12 / (total % 4) : 3;
};

const IconTextLockup = ({ data: { items, spaceBelow } }) => (
  <SectionWrapper spaceBelow={spaceBelow}>
    <Grid>
      <Row>
        {items.map(({ __typename, id, citation, image, organization, quote, summary, title }, i) =>
          __typename === 'ContentfulIconTextLockupItem' ? (
            // icon text lockup item
            <Col key={id} sm={12} md={6} lg={4}>
              <StyledGrid>
                <StyledRow>
                  {image && (
                    <Col sm={3} md={2} lg={3}>
                      <Img fluid={image.fluid} alt={image.title} />
                    </Col>
                  )}
                  <Col sm={9} md={10} lg={9}>
                    <HeadingElement element="h2" level={3} marginBottom={S_025}>
                      {title}
                    </HeadingElement>
                    <Summary>{summary}</Summary>
                  </Col>
                </StyledRow>
              </StyledGrid>
            </Col>
          ) : (
            // testimonial
            <TestimonialCol key={id} sm={6} md={6} lg={colSize(items.length, i)}>
              {image && (
                <TestimonialImage>
                  <Img fluid={image.fluid} alt={image.title} />
                </TestimonialImage>
              )}
              <Testimonial>
                {ReactHtmlParser(quote.childMarkdownRemark.html)}
                <TestimonialCite>
                  &ndash; {citation}
                  {citation && organization ? ', ' : ''}
                  {organization}
                </TestimonialCite>
              </Testimonial>
            </TestimonialCol>
          ),
        )}
      </Row>
    </Grid>
  </SectionWrapper>
);

export default IconTextLockup;

export const query = graphql`
  fragment IconTextLockup on ContentfulPageIconTextLockup {
    id
    __typename
    items {
      __typename
      ... on ContentfulIconTextLockupItem {
        id
        image {
          fluid(maxWidth: 80) {
            aspectRatio
            sizes
            src
            srcSet
          }
          title
        }
        summary
        title
      }
      ... on ContentfulPageTestimonial {
        ...Testimonial
      }
    }
    spaceBelow
  }
`;
