import React from 'react';

import { LogoLink, LogoPath } from './styles';

const Logo = ({ className, height, width }) => (
  <LogoLink to="/">
    <LogoPath
      className={className}
      fill="currentColor"
      height={height && `${height}px`}
      viewBox="0 0 358 64"
      width={width && `${width}px`}
    >
      <path d="M33.164 63.037C13.565 63.037.282 49.835.282 32.041.282 14.164 13.565.963 33.164.963c6.97 0 13.366 2.05 18.778 5.248l-4.264 11.808c-4.428-2.46-9.43-4.018-14.35-4.018-11.234 0-18.204 6.888-18.204 18.04 0 11.151 6.97 18.04 18.204 18.04 4.92 0 10.496-1.641 15.006-4.429l4.183 11.809c-5.248 3.444-12.465 5.576-19.353 5.576M83.02 62.053V1.946h13.94v47.397h24.108v12.71H83.02M172.153 26.055l-5.985 14.431h11.89l-5.905-14.431zm14.842 35.998l-4.51-10.907h-20.746l-4.51 10.907h-14.185l28.29-60.599h1.886l28.29 60.599h-14.515zM248.002 14.001c-2.132 0-3.443.082-5.412.409v16.565c1.805.164 2.788.246 4.429.246 6.641 0 10.906-3.199 10.906-9.266 0-5.003-3.937-7.954-9.923-7.954zm12.383 48.052l-10.25-19.598c-.821.082-1.559.082-2.379.082-2.05 0-3.197-.082-5.166-.164v19.68h-13.94V2.521c5.084-.575 10.661-.985 17.712-.985 18.697 0 26.076 8.939 26.076 19.927 0 7.134-3.854 13.53-10.332 17.384l14.105 23.206h-15.826zM340.908 62.053L318.85 33.927v28.126h-13.941V1.946h13.941v26.158l21.074-26.158h16.482l-24.682 28.618 25.994 31.489h-16.81" />
    </LogoPath>
  </LogoLink>
);

export default Logo;
