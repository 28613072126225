import styled from 'styled-components';
import { Grid, Row, Col, COLORS, LINE_HEIGHT, MEDIA, SPACING, TYPE_SCALE } from 'clark-styles';

const { GREY_75 } = COLORS;
const { COPY } = LINE_HEIGHT;
const { S_1, S_2, S_4 } = SPACING;
const { TS_4 } = TYPE_SCALE;

const TestimonialImageSize = '6.25rem';

export const StyledGrid = styled(Grid)`
  padding: 0;
  ${MEDIA.small`
    padding: 0;
  `};
`;

export const StyledRow = styled(Row)`
  margin: 0;
`;

export const Summary = styled.p`
  ${TS_4};
  ${COPY};
  margin-bottom: ${S_4};
`;

export const Testimonial = styled.blockquote`
  ${COPY};
  text-align: center;
`;

export const TestimonialCite = styled.cite`
  color: ${GREY_75};
  font-style: italic;
`;

export const TestimonialCol = styled(Col)`
  margin-bottom: ${S_2} !important;
  ${MEDIA.medium`
    margin-bottom: 0;
  `};
`;

export const TestimonialImage = styled.div`
  margin: 0 auto ${S_1};
  max-width: ${TestimonialImageSize};
`;
