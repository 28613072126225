import styled from 'styled-components';
import { COLORS, SPACING, TYPE_SCALE, FONT_WEIGHT } from 'clark-styles';
import { FormInputStyle } from '../input/styles';

const { S_05 } = SPACING;
const { GREY_25 } = COLORS;
const { TS_6 } = TYPE_SCALE;
const { FW_100 } = FONT_WEIGHT;

export const FormTextArea = styled.textarea`
  ${FormInputStyle}
`;
export const CharacterCount = styled.div`
  margin-top: ${S_05};
  color: ${GREY_25};
  ${FW_100};
  ${TS_6};
`;
