import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Grid, Row, Col } from 'clark-styles';

import HeadingElement from '../heading-element';
import SectionWrapper from '../section-wrapper';
import UtilityText from '../utility-text';

import { StyledCol, ImageWrapper, Summary } from './styles';

const FeatureAsset = ({
  props: {
    bgImage,
    image: { fluid, title },
  },
  leftColText,
}) => (
  <ImageWrapper bg={bgImage && bgImage.file.url} leftColText={leftColText}>
    <Img fluid={fluid} alt={title} />
  </ImageWrapper>
);

const FeatureCopy = ({
  props: {
    eyebrow,
    heading,
    summary: {
      childMarkdownRemark: { html },
    },
  },
}) => (
  <>
    {eyebrow && (
      <UtilityText color="primary" hasMarginBottom>
        {eyebrow}
      </UtilityText>
    )}
    <HeadingElement element="h2" level={1}>
      {heading}
    </HeadingElement>
    <Summary>{ReactHtmlParser(html)}</Summary>
  </>
);

const FeatureHighlight = ({ data: { alignText: leftColText, spaceBelow, ...props } }) => (
  <SectionWrapper spaceBelow={spaceBelow}>
    <Grid>
      <Row>
        <StyledCol sm={12} md={7} lg={6}>
          {leftColText ? <FeatureCopy props={props} /> : <FeatureAsset props={props} />}
        </StyledCol>
        <Col sm={12} md={5} lg={5} lgOffset={1}>
          {leftColText ? <FeatureAsset leftColText props={props} /> : <FeatureCopy props={props} />}
        </Col>
      </Row>
    </Grid>
  </SectionWrapper>
);

export default FeatureHighlight;

export const query = graphql`
  fragment FeatureHighlight on ContentfulPageFeatureHighlight {
    id
    __typename
    eyebrow
    heading
    alignText
    summary {
      childMarkdownRemark {
        html
      }
    }
    spaceBelow
    image {
      title
      fluid {
        aspectRatio
        src
        srcSet
        sizes
      }
    }
    bgImage {
      title
      file {
        url
      }
    }
  }
`;
