import React from 'react';
import { graphql } from 'gatsby';
import { Col } from 'clark-styles';
import { formatDollars } from 'clark-utils';
import moment from 'moment';

import { trackEvent } from '../../shared/analytics';

import Button from '../button';
import HeadingElement from '../heading-element';
import SectionWrapper from '../section-wrapper';
import UtilityText from '../utility-text';

import { StyledGrid, StyledRow, Summary, EventMeta, EventIcon } from './styles';

const Event = ({
  data: {
    title,
    eventType,
    eventSummary,
    startTime,
    endTime,
    expiryTime,
    rsvpLink,
    spaceBelow,
    dateCopy,
    timeCopy,
    cost,
  },
}) => {
  let date;
  let formattedStartTime;
  let formattedEndTime;

  if (!!startTime && !!endTime) {
    const startTimeMoment = moment(startTime);
    const endTimeMoment = moment(endTime);
    const isSameDate = startTimeMoment.isSame(endTimeMoment, 'date');
    const isSameYear = startTimeMoment.isSame(endTimeMoment, 'year');

    const formattedDates = isSameYear
      ? `${startTimeMoment.format('MMM D')} - ${endTimeMoment.format('ll')}`
      : `${startTimeMoment.format('ll')} - ${endTimeMoment.format('ll')}`;

    date = isSameDate ? startTimeMoment.format('ll') : formattedDates;
    formattedStartTime = startTimeMoment.format('LT');
    formattedEndTime = endTimeMoment.format('LT');
  }

  const isExpired = !!expiryTime && moment(expiryTime).isSameOrBefore(moment());

  return (
    <>
      {!isExpired && (
        <SectionWrapper spaceBelow={spaceBelow}>
          <StyledGrid>
            <StyledRow>
              <Col sm={12} md={5} mdOffset={1} lg={4} lgOffset={1}>
                {eventType && (
                  <UtilityText color="primary" hasMarginBottom>
                    {eventType}
                  </UtilityText>
                )}
                <HeadingElement element="h3" level={3}>
                  {title}
                </HeadingElement>
                <Summary>{eventSummary}</Summary>
              </Col>
              <Col sm={12} md={5} lg={4} lgOffset={1}>
                <div>
                  <EventIcon name="calendar" size={48} />
                  <EventMeta>
                    <strong>{dateCopy || date}</strong>
                    <br />
                    {timeCopy || `${formattedStartTime} - ${formattedEndTime}`}
                    {cost && (
                      <>
                        <br />
                        {formatDollars(cost)}
                      </>
                    )}
                  </EventMeta>
                </div>
                <Button path={rsvpLink} onClick={() => trackEvent('RSVP Button Clicked')}>
                  RSVP
                </Button>
              </Col>
            </StyledRow>
          </StyledGrid>
        </SectionWrapper>
      )}
    </>
  );
};

export default Event;

export const query = graphql`
  fragment Event on ContentfulPageEvent {
    id
    __typename
    title
    eventType
    eventSummary: summary
    startTime
    endTime
    expiryTime
    rsvpLink
    spaceBelow
    dateCopy
    timeCopy
    cost
  }
`;
