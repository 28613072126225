import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, getFormValues, getFormSyncErrors } from 'redux-form';
import { injectStripe } from 'react-stripe-elements';
import { localStorageClear } from 'clark-utils';
import { isRequired as requiredField } from '../../shared/validations';

import { MATCHED_OPTIONS } from '../matches';
import { trackEvent } from '../../shared/analytics';
import Input from '../input';
import Dropdown from '../dropdown';
import { Error } from '../input/styles';
import {
  getInput,
  submitSimpleTrackingForm,
  progressFindATutor,
  submitBuildApplicationForm,
  submitConsultationForm,
  submitFindATutorForm,
} from './actions';
import { fetchMasterSubjects, identifyLead, submitEvent } from '../../shared/api';
import LoadingMatch from '../matches/loading-match.gif';

import {
  FIND_A_TUTOR_FORM_TYPE,
  BUILD_APPLICATION_FORM_TYPE,
  BOOK_A_CONSULTATION_FORM_TYPE,
  SIMPLE_TRACKING_FORM_TYPE,
  ChildInputsData,
} from './constants';
import {
  setErrorMessage,
  setIsLoadingToTrue,
  setIsLoadingToFalse,
  setFormValues,
  clearFormValues,
  setLocationData,
} from '../../state/actions';

import { FormStep, FormStepQuestion, SubmitButton } from './styles';

const mapStateToProps = state => {
  const formValues = getFormValues('collectionForm')(state);
  const hasValidationErrors = Object.keys(getFormSyncErrors('collectionForm')(state)).length > 0;
  const {
    state: { isLoading, trackedFormValues, isStripeComplete, errorMessage },
  } = state;
  const collectedFormValues = { ...trackedFormValues, ...formValues };
  return {
    trackedFormValues,
    collectedFormValues,
    hasValidationErrors,
    isLoading,
    isStripeComplete,
    errorMessage,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchErrorMessage: message => dispatch(setErrorMessage(message)),
    dispatchSetIsLoadingToTrue: () => dispatch(setIsLoadingToTrue()),
    dispatchSetIsLoadingToFalse: () => dispatch(setIsLoadingToFalse()),
    dispatchSetFormValues: formValues => dispatch(setFormValues(formValues)),
    dispatchClearFormValues: () => dispatch(clearFormValues()),
    dispatchSetLocationData: data => dispatch(setLocationData(data)),
  };
};

const Form = props => {
  const {
    steps,
    selectAnswer,
    trackedFormValues,
    collectedFormValues,
    formType,
    trackingDescription,
    hasSubmitButton,
    formContinuationLink,
    stripe,
    confirm,
    hasValidationErrors,
    isLoading,
    isStripeComplete,
    errorMessage,
    dispatchErrorMessage,
    dispatchSetIsLoadingToTrue,
    dispatchSetIsLoadingToFalse,
    dispatchSetFormValues,
    dispatchClearFormValues,
    dispatchSetLocationData,
  } = props;
  const [subjects, setSubjects] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    localStorageClear(MATCHED_OPTIONS);
    fetchMasterSubjects().then(allSubjects => {
      const formattedSubjects = allSubjects.map(({ id, name }) => ({ value: id, label: name }));
      setSubjects(formattedSubjects);
    });
  }, []);

  const isWindowDefined = typeof window !== 'undefined';

  const showSubjectInput = formType === FIND_A_TUTOR_FORM_TYPE && !trackedFormValues.subjectId;
  const showChildInputs =
    formType === FIND_A_TUTOR_FORM_TYPE &&
    collectedFormValues.personBeingTutored &&
    collectedFormValues.personBeingTutored !== 'For me';
  const completedFields = collectedFormValues
    ? Object.keys(collectedFormValues).filter(x => x)
    : [];
  const fieldsLeft = steps.length + (showSubjectInput ? 1 : 0) - completedFields.length;

  const areRequiredFieldsCompleted =
    !!collectedFormValues &&
    (!showSubjectInput || Object.keys(collectedFormValues).includes('subjectId')) &&
    steps
      .filter(x => x.isRequired && x.inputType !== 'stripe')
      .every(
        x =>
          Object.keys(collectedFormValues).includes(x.propertyName) &&
          collectedFormValues[x.propertyName] != null,
      );
  const hasStripeElement = steps.some(x => x.inputType === 'stripe');
  const disabledSubmit =
    !areRequiredFieldsCompleted ||
    hasValidationErrors ||
    isLoading ||
    (hasStripeElement && !isStripeComplete);
  const save = (propertyName, response) => {
    dispatchSetFormValues({ ...collectedFormValues, [propertyName]: response });
    if (formType === BUILD_APPLICATION_FORM_TYPE) {
      const {
        first_name: firstName,
        last_name: lastName,
        Email,
        phoneNumber,
      } = collectedFormValues;
      identifyLead(firstName, lastName, Email, phoneNumber);
      submitEvent('Build Application Part 1 Completed', {
        ...collectedFormValues,
        [propertyName]: response,
      });
    }
  };
  return showLoading ? (
    <img src={LoadingMatch} alt="Loading Match" />
  ) : (
    <form>
      {isWindowDefined && (
        <>
          {showSubjectInput && (
            <FormStep>
              <FormStepQuestion>* Subject</FormStepQuestion>
              <Dropdown
                type="dropdown"
                name="subjectId"
                validate={requiredField}
                onChange={(_, response) => {
                  const subject = subjects.find(x => x.value === response).label;
                  dispatchSetFormValues({ ...trackedFormValues, subject });
                  trackEvent('Find a Tutor Form: Subject', {
                    question: 'Subject',
                    response: subject,
                    incompleteFieldsCount: completedFields.includes('subjectId')
                      ? fieldsLeft
                      : fieldsLeft - 1,
                  });
                }}
                options={subjects}
              />
            </FormStep>
          )}
          {steps.map(
            ({
              id,
              question,
              options,
              inputType,
              placeholder,
              isRequired,
              propertyName,
              characterLimit,
            }) => {
              const incompleteFieldsCount = completedFields.includes(question)
                ? fieldsLeft
                : fieldsLeft - 1;
              return (
                <FormStep key={id}>
                  <FormStepQuestion>
                    {isRequired ? '* ' : ''}
                    {question}
                  </FormStepQuestion>
                  {getInput(
                    trackingDescription,
                    question,
                    options,
                    inputType,
                    placeholder,
                    isRequired,
                    propertyName,
                    characterLimit,
                    selectAnswer,
                    incompleteFieldsCount,
                    formType,
                    stripe,
                    save,
                    disabledSubmit,
                  )}
                </FormStep>
              );
            },
          )}
          {showChildInputs &&
            ChildInputsData.map(data => (
              <FormStep key={data.label}>
                <FormStepQuestion>{`* ${data.label}`}</FormStepQuestion>
                <Input {...data} />
              </FormStep>
            ))}
          {formType === FIND_A_TUTOR_FORM_TYPE && hasSubmitButton && (
            <SubmitButton
              disabled={disabledSubmit}
              onClick={e => {
                e.preventDefault();
                submitFindATutorForm(collectedFormValues, setShowLoading);
              }}
            >
              Find A Tutor!
            </SubmitButton>
          )}
          {formType === FIND_A_TUTOR_FORM_TYPE && !hasSubmitButton && (
            <SubmitButton
              type="submit"
              disabled={disabledSubmit}
              path={disabledSubmit ? null : formContinuationLink}
              onClick={() =>
                progressFindATutor(
                  collectedFormValues,
                  dispatchSetFormValues,
                  dispatchSetLocationData,
                )
              }
            >
              Next
            </SubmitButton>
          )}
          {formType === BUILD_APPLICATION_FORM_TYPE && hasSubmitButton && (
            <SubmitButton
              type="button"
              disabled={disabledSubmit}
              onClick={() =>
                submitBuildApplicationForm(
                  collectedFormValues,
                  stripe,
                  dispatchErrorMessage,
                  dispatchSetIsLoadingToTrue,
                  dispatchSetIsLoadingToFalse,
                  dispatchClearFormValues,
                )
              }
            >
              Pay deposit and Submit Application
            </SubmitButton>
          )}
          {formType === BOOK_A_CONSULTATION_FORM_TYPE && (
            <>
              <SubmitButton
                type="button"
                disabled={disabledSubmit}
                onClick={() =>
                  submitConsultationForm(collectedFormValues, confirm, dispatchErrorMessage)
                }
              >
                Send
              </SubmitButton>
              <Error>{errorMessage}</Error>
            </>
          )}
          {formType === SIMPLE_TRACKING_FORM_TYPE && (
            <SubmitButton
              type="submit"
              disabled={disabledSubmit}
              path={disabledSubmit ? null : formContinuationLink}
              onClick={() => submitSimpleTrackingForm(collectedFormValues, trackingDescription)}
            >
              Submit
            </SubmitButton>
          )}
        </>
      )}
    </form>
  );
};

export default injectStripe(
  compose(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
    reduxForm({
      form: 'collectionForm',
    }),
  )(Form),
);
