import styled from 'styled-components';
import { Grid, Row, COLORS, LINE_HEIGHT, MEDIA, SPACING, TYPE_SCALE } from 'clark-styles';

import Icon from '../icon';

const { WHITE, GREY_100 } = COLORS;
const { COPY } = LINE_HEIGHT;
const { S_05, S_1, S_2, S_4 } = SPACING;
const { TS_3, TS_4 } = TYPE_SCALE;
const colOffset = '30px';
const metaPadding = '72px';

export const StyledGrid = styled(Grid)`
  margin-bottom: calc(${S_05} + ${S_1});
  ${MEDIA.small`
    margin-bottom: ${S_05};
  `};
`;

export const StyledRow = styled(Row)`
  background-color: ${WHITE};
  padding: ${S_4} calc(${S_05} + ${S_1});
  ${MEDIA.small`
    padding-left: 0;
    padding-right: 0;
  `};
`;

export const Summary = styled.p`
  ${COPY};
  margin-bottom: ${S_2};
  ${MEDIA.small`
    ${TS_4};
    margin-bottom: 0;
  `};
`;

export const EventIcon = styled(Icon)`
  color: ${GREY_100};
  position: absolute;
`;

export const EventMeta = styled.p`
  ${TS_3};
  ${COPY};
  margin-bottom: ${S_2};
  padding-left: ${metaPadding};
  position: relative;
  ${MEDIA.small`
    margin-top: ${colOffset};
  `};
`;
