import React, { useState } from 'react';
import { connect } from 'react-redux';

import { navigate } from 'gatsby';
import { trackEvent } from '../../shared/analytics';
import { setFormValues } from '../../state/actions';
import Button from '../button';

import { Form, FormLabel, FormInput } from './styles';

const mapDispatchToProps = dispatch => {
  return {
    dispatchSetFormValues: formValues => dispatch(setFormValues(formValues)),
  };
};
const HeroInput = ({
  buttonText,
  inputLabel,
  inputName,
  inputPlaceholder,
  buttonLink,
  dispatchSetFormValues,
  location,
}) => {
  const [textInput, setValue] = useState(null);
  const defaultLink = '/demo-form/';
  const handleInputChange = ({ target }) => {
    setValue(target.value);
  };

  const handleSubmit = () => {
    trackEvent(`${inputName} Submitted`, { [inputName]: textInput });
    dispatchSetFormValues({ [inputName]: textInput, entryPath: location.pathname });
    navigate(buttonLink || defaultLink);
  };

  // handle "enter"
  const isdocumentDefined = typeof document !== 'undefined';
  const input = isdocumentDefined ? document.getElementById('heroInput') : null;
  const handleEnter = e => {
    if (e.keyCode === 13) {
      handleSubmit();
      input.removeEventListener('keyup', handleEnter);
    }
  };
  if (input) {
    input.addEventListener('keyup', handleEnter);
  }
  return (
    <Form>
      <FormLabel htmlFor={inputName}>{inputLabel}</FormLabel>
      <FormInput
        id="heroInput"
        name={inputName}
        type="text"
        placeholder={inputPlaceholder}
        onChange={handleInputChange}
        onkeydown={handleEnter}
      />
      <Button type="submit" onClick={handleSubmit}>
        {buttonText || 'Get started'}
      </Button>
    </Form>
  );
};

export default connect(
  null,
  mapDispatchToProps,
)(HeroInput);
