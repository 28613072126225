import React from 'react';

import StyledHeading from './styles';

const HeadingElement = ({
  centered,
  children,
  className,
  element = 'h4',
  marginBottom,
  level = 4,
}) => (
  <StyledHeading
    as={element}
    centered={centered}
    className={className}
    level={level}
    marginBottom={marginBottom}
  >
    {children}
  </StyledHeading>
);

export default HeadingElement;
