import styled, { css } from 'styled-components';
import {
  Grid,
  Col,
  COLORS,
  LINE_HEIGHT,
  MEDIA,
  SPACING,
  TRANSITIONS,
  TYPE_SCALE,
  Z_INDEX,
} from 'clark-styles';

import Link from '../link';
import UtilityText from '../utility-text';

const { CLARK_PRIMARY, CLARK_SECONDARY, GREY_25, GREY_100, PURPLE, WHITE, YELLOW } = COLORS;
const { COPY } = LINE_HEIGHT;
const { S_2, S_4, S_8 } = SPACING;
const { EASE_IN_OUT_25 } = TRANSITIONS;
const { TS_4 } = TYPE_SCALE;
const { Z_BOTTOM } = Z_INDEX;
const pseudoSize = '1px';
const gridlineOffset = '12px';

const highlight = {
  green: () => CLARK_SECONDARY,
  purple: () => PURPLE,
  red: () => CLARK_PRIMARY,
  yellow: () => YELLOW,
};

export const GridCol = styled(Col)`
  padding-bottom: ${S_4};
  padding-top: ${S_4};
  position: relative;
  text-align: center;
  ${({ dividers }) =>
    dividers &&
    css`
      &:not(:last-of-type)::after {
        background-color: ${GREY_25};
        bottom: 0;
        content: '';
        height: ${pseudoSize};
        left: 0;
        position: absolute;
        width: 100%;
        ${MEDIA.small`
      width: calc(100% + ${gridlineOffset});
    `};
      }

      :nth-last-child(2)::after {
        ${MEDIA.small`
      display: none;
    `};
      }

      :nth-last-child(3)::after {
        ${MEDIA.medium`
      display: none;
    `};
      }

      &:nth-child(even) {
        &::after {
          ${MEDIA.small`
        left: -${gridlineOffset};
      `};
        }

        &::before {
          ${MEDIA.small`
        background-color: ${GREY_25};
        content: '';
        height: 100%;
        left: -${gridlineOffset};
        position: absolute;
        top: 0;
        width: ${pseudoSize};
      `};
        }
      }

      &:nth-child(3n + 1) {
        &::before {
          ${MEDIA.medium`
        display: none;
      `};
        }
      }

      &:nth-child(3n + 2),
      &:nth-child(3n + 3) {
        &::before {
          ${MEDIA.medium`
        background-color: ${GREY_25};
        content: '';
        height: 100%;
        left: -${gridlineOffset};
        position: absolute;
        top: 0;
        width: ${pseudoSize};
        ${Z_BOTTOM};
      `};
        }
      }

      &:nth-child(3n + 2) {
        &::after {
          ${MEDIA.medium`
        width: calc(100% + (${gridlineOffset} * 2));
      `};
        }
      }

      &:nth-child(3n + 3) {
        &::after {
          ${MEDIA.medium`
        left: -${gridlineOffset};
      `};
        }
      }
    `}
`;

export const GridImg = styled.img`
  margin-bottom: ${S_2};
`;

export const GridLink = styled(Link)`
  color: ${GREY_100};
  display: block;
  position: relative;
  text-decoration: none;

  &::before {
    background-color: ${({ background }) => highlight[background]};
    content: '';
    height: calc(100% + ${S_8});
    left: 0;
    opacity: 0;
    position: absolute;
    top: -${S_4};
    ${EASE_IN_OUT_25};
    width: 100%;
  }

  &:hover {
    color: ${WHITE};

    &::before {
      opacity: 1;
      transform: scale(1.1);
    }

    p {
      color: ${WHITE};
    }
  }
`;

export const LinkText = styled(UtilityText)`
  color: ${({ color }) => highlight[color]};
`;

export const NestedGrid = styled(Grid)`
  position: relative;
`;

export const Summary = styled.p`
  ${TS_4};
  ${COPY};
  margin-bottom: ${S_2};
`;
