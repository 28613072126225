import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Row, Col } from 'clark-styles';

import SectionWrapper from '../section-wrapper';
import {
  StyledGrid,
  StyledRow,
  PositionedCols,
  ImageSmall,
  ImageLarge,
  QuoteGrid,
  Quote,
  Cite,
} from './styles';

const Blockquote = ({
  data: {
    alignText,
    spaceBelow,
    quote: {
      childMarkdownRemark: { html },
    },
    citation,
    organization,
    image,
  },
}) => (
  <SectionWrapper spaceBelow={spaceBelow}>
    <StyledGrid alignRight={alignText}>
      <StyledRow alignRight={alignText}>
        <Col
          sm={9}
          smOffset={alignText ? 0 : 3}
          md={11}
          mdOffset={alignText ? 0 : 1}
          lg={8}
          lgOffset={alignText ? 0 : 4}
        >
          <QuoteGrid>
            <Row>
              <Col sm={12} md={9} mdOffset={alignText ? 0 : 3} lg={12}>
                <Quote>{ReactHtmlParser(html)}</Quote>
              </Col>
            </Row>
            <Row>
              <Col sm={8} smOffset={alignText ? 0 : 4} md={9} mdOffset={alignText ? 0 : 3} lg={12}>
                <Cite color="white">
                  -{citation}
                  {organization && ReactHtmlParser(`, <br />${organization}`)}
                </Cite>
              </Col>
              {image && (
                <ImageSmall alignRight={alignText}>
                  <Img fluid={image.fluid} alt={image.title} />
                </ImageSmall>
              )}
            </Row>
          </QuoteGrid>
        </Col>
        {image && (
          <PositionedCols md={4} lg={3} lgOffset={alignText ? 7 : 2} alignRight={alignText}>
            <ImageLarge>
              <Img fluid={image.fluid} alt={image.title} />
            </ImageLarge>
          </PositionedCols>
        )}
      </StyledRow>
    </StyledGrid>
  </SectionWrapper>
);

export default Blockquote;

export const query = graphql`
  fragment Testimonial on ContentfulPageTestimonial {
    id
    __typename
    alignText
    spaceBelow
    quote {
      childMarkdownRemark {
        html
      }
    }
    citation
    organization
    image {
      title
      fluid(maxWidth: 330, maxHeight: 330) {
        aspectRatio
        src
        srcSet
        sizes
      }
    }
  }
`;
