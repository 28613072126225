import { Link } from 'gatsby';
import styled from 'styled-components';
import { COLORS } from 'clark-styles';

const { CLARK_PRIMARY } = COLORS;

export const LogoLink = styled(Link)`
  color: ${CLARK_PRIMARY};
`;

export const LogoPath = styled.svg`
  display: block;
`;
