import { css } from 'styled-components';

import GraphikRegular from './fonts/Graphik-Regular.woff';
import GraphikRegularItalic from './fonts/Graphik-RegularItalic.woff';
import GraphikMedium from './fonts/Graphik-Medium.woff';
import GraphikSemibold from './fonts/Graphik-Semibold.woff';
import GraphikSemiboldItalic from './fonts/Graphik-SemiboldItalic.woff';
import GraphikBold from './fonts/Graphik-Bold.woff';
import VerlagBlack from './fonts/Verlag-Black.woff';

export const FontPaths = {
  GraphikRegular,
  GraphikRegularItalic,
  GraphikMedium,
  GraphikSemibold,
  GraphikSemiboldItalic,
  GraphikBold,
  VerlagBlack,
};

const PRIMARY_FONT = css`
  font-family: 'Graphik', sans-serif;
`;

const SECONDARY_FONT = css`
  font-family: 'Verlag', sans-serif;
  text-transform: uppercase;
`;

const FS_ITALIC = css`
  font-style: italic;
`;

const FW_NORMAL = css`
  font-weight: normal;
`;

const FW_MEDIUM = css`
  font-weight: 500;
`;

const FW_SEMIBOLD = css`
  font-weight: 600;
`;

const FW_BOLD = css`
  font-weight: 700;
`;

const FW_BLACK = css`
  font-weight: 800;
`;

export const FONTS = {
  PRIMARY_FONT,
  SECONDARY_FONT,
};

export const FONT_STYLE = {
  FS_ITALIC,
};

export const FONT_WEIGHT = {
  FW_NORMAL,
  FW_MEDIUM,
  FW_SEMIBOLD,
  FW_BOLD,
  FW_BLACK,
};
