import { isRequired } from '../../shared/validations';
import { trackEvent } from '../../shared/analytics';

export const FIND_A_TUTOR_FORM_TYPE = 'Find a Tutor';
export const BUILD_APPLICATION_FORM_TYPE = 'Build Application';
export const BOOK_A_CONSULTATION_FORM_TYPE = 'Book a Consultation';
export const SIMPLE_TRACKING_FORM_TYPE = 'Simple Tracking Form';
export const MATCHING_OPTIONS_FORM_TYPE = 'Matching Options';

export const EventTitlePrefixMap = {
  [FIND_A_TUTOR_FORM_TYPE]: 'Find a Tutor Form:',
  [BUILD_APPLICATION_FORM_TYPE]: 'Build Application Form:',
  [BOOK_A_CONSULTATION_FORM_TYPE]: 'Book a Consultation Form:',
};

export const PROSPECT_INFORMATION = 'PROSPECT_INFORMATION';

export const ChildInputsData = [
  {
    type: 'input',
    name: 'studentFirstName',
    validate: isRequired,
    label: 'Student First Name',
    onBlur: (_, response) =>
      trackEvent('Find a Tutor Form: Student First Name', {
        question: 'Student First Name',
        response,
      }),
  },
  {
    type: 'input',
    name: 'studentLastName',
    validate: isRequired,
    label: 'Student Last Name',
    onBlur: (_, response) =>
      trackEvent('Find a Tutor Form: Student Last Name', {
        question: 'Student Last Name',
        response,
      }),
  },
];
