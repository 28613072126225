import styled from 'styled-components';
import { BORDER_RADIUS, BORDER_WIDTH, COLORS, SPACING, TYPE_SCALE } from 'clark-styles';

import { FONT_WEIGHT } from '../../shared/typography';

const { BR_2 } = BORDER_RADIUS;
const { BW_1 } = BORDER_WIDTH;
const { GREY_10, GREY_75 } = COLORS;
const { FW_SEMIBOLD } = FONT_WEIGHT;
const { S_1, S_2 } = SPACING;
const { TS_4 } = TYPE_SCALE;

export const Form = styled.form`
  display: ${({ submitted }) => (submitted === 'success' ? 'none' : 'block')};
`;

export const FormLegend = styled.legend`
  ${FW_SEMIBOLD};
  margin-bottom: ${S_1};
`;

export const FormLabel = styled.label`
  font-size: 0;
`;

export const FormInput = styled.input`
  appearance: none;
  border: ${BW_1} solid ${GREY_10};
  ${BR_2};
  color: ${GREY_75};
  ${TS_4};
  margin-bottom: ${S_1};
  padding: ${S_1} ${S_2};
  width: 100%;
`;

export const FormResponse = styled.p`
  margin-top: ${({ submitted }) => (submitted === 'success' ? 0 : S_1)};
`;
