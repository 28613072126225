import React from 'react';
import { graphql } from 'gatsby';
import { Grid, Row, Col, SPACING } from 'clark-styles';

import Button from '../button';
import HeadingElement from '../heading-element';
import { trackConversion, trackEvent } from '../../shared/analytics';

import SectionWrapper from '../section-wrapper';
import SummaryText from './styles';

const { S_2 } = SPACING;

const CTA = ({ data: { heading, summaryText, buttonText, link, spaceBelow } }) => (
  <SectionWrapper spaceBelow={spaceBelow}>
    <Grid>
      {heading && (
        <Row>
          <Col sm={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
            <HeadingElement centered element="h2" level={1} marginBottom={S_2}>
              {heading}
            </HeadingElement>
          </Col>
        </Row>
      )}
      {summaryText && (
        <Row>
          <Col sm={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
            <SummaryText>{summaryText}</SummaryText>
          </Col>
        </Row>
      )}
      <Row>
        <Col sm={12} md={8} mdOffset={2} lg={4} lgOffset={4}>
          <p>
            <Button
              path={link}
              onClick={e => {
                trackConversion(e);
                trackEvent('CTA Module Button Clicked', {
                  buttonText,
                });
              }}
            >
              {buttonText}
            </Button>
          </p>
        </Col>
      </Row>
    </Grid>
  </SectionWrapper>
);

export default CTA;

export const query = graphql`
  fragment CTA on ContentfulPageCta {
    id
    __typename
    heading
    summaryText
    buttonText
    link
    spaceBelow
  }
`;
