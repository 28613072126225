import React, { Fragment } from 'react';
import { Field } from 'redux-form';

import { InputContainer, FormInput, Error } from './styles';

const renderField = ({ input, inputType, meta: { touched, error }, ...rest }) => (
  <Fragment>
    {/* we rename the inputType prop to avoid a colision with the type attribute
    that is used to specify which form element to render */}
    <InputContainer showError={!(error && touched)}>
      <FormInput {...rest} {...input} showError={!(error && touched)} type={inputType} />
    </InputContainer>
    <div>{touched && (error && <Error>{error}</Error>)}</div>
  </Fragment>
);
const Input = ({ disabled, inputType, name, ...rest }) => (
  <Field {...rest} component={renderField} disabled={disabled} inputType={inputType} name={name} />
);
export default Input;
