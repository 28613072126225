import React from 'react';
import Helmet from 'react-helmet';

import Facebook from './facebook';
import Twitter from './twitter';

import favicon from '../../favicon.ico';

const MetaTags = ({
  defaultMeta: {
    defaultMetaDescription,
    defaultMetaImage: {
      file: { url: defaultImage },
    },
    defaultMetaTitle,
    twitterUsername,
  },
  location: { href, origin },
  pageMeta: { metaDescription, metaImage, metaTitle },
}) => {
  const meta = {
    title: metaTitle || defaultMetaTitle,
    description: metaDescription || defaultMetaDescription,
    image: `${origin}${metaImage ? metaImage.file.url : defaultImage}`,
    url: href,
  };
  return (
    <>
      <Helmet title={meta.title} titleTemplate={`%s | ${defaultMetaTitle}`}>
        <link rel="icon" href={favicon} />
        <meta name="description" content={meta.description} />
        <meta name="image" content={meta.image} />
        <script type="text/javascript">{`(function(o){var b="https://fastfinch.co/anywhere/",t="81a82949f1f64a9ab77993f1281f1b93163008f4785a4ce99b3575e70afc5f3f",a=window.AutopilotAnywhere={_runQueue:[],run:function(){this._runQueue.push(arguments);}},c=encodeURIComponent,s="SCRIPT",d=document,l=d.getElementsByTagName(s)[0],p="t="+c(d.title||"")+"&u="+c(d.location.href||"")+"&r="+c(d.referrer||""),j="text/javascript",z,y;if(!window.Autopilot) window.Autopilot=a;if(o.app) p="devmode=true&"+p;z=function(src,asy){var e=d.createElement(s);e.src=src;e.type=j;e.async=asy;l.parentNode.insertBefore(e,l);};y=function(){z(b+t+'?'+p,true);};if(window.attachEvent){window.attachEvent("onload",y);}else{window.addEventListener("load",y,false);}})({});`}</script>
      </Helmet>
      <Facebook
        description={meta.description}
        image={meta.image}
        pageUrl={meta.url}
        siteName={defaultMetaTitle}
        title={meta.title}
      />
      <Twitter
        description={meta.description}
        image={meta.image}
        title={meta.title}
        username={twitterUsername}
      />
    </>
  );
};

export default MetaTags;
