import styled from 'styled-components';
import { Col, BOX_SHADOW, MEDIA, SPACING } from 'clark-styles';

const { BS_LARGE } = BOX_SHADOW;
const { S_2 } = SPACING;

const ImageCol = styled(Col)`
  ${BS_LARGE};
  margin-top: ${S_2};
  ${MEDIA.medium`
    margin-top: 0;
  `};
`;

export default ImageCol;
