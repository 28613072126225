import React from 'react';
import { InnerRadio, OuterRadio, Label, RadioButtonFlex } from './styles';

const RadioButton = ({ index, input, isSelected, label, name, onClickHandler, value }) => (
  <RadioButtonFlex alignItems="center">
    <OuterRadio onClick={() => onClickHandler(input, isSelected, value)}>
      <InnerRadio
        index={index}
        name={name}
        onClick={() => onClickHandler(input, isSelected, value)}
        selected={isSelected}
        type="radio"
        value={value}
      />
    </OuterRadio>
    <Label
      htmlFor={name}
      deselected={!!input.value && !isSelected}
      onClick={() => onClickHandler(input, isSelected, value)}
    >
      {label}
    </Label>
  </RadioButtonFlex>
);

export default RadioButton;
