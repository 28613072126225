import React from 'react';
import { Field } from 'redux-form';

import { Error } from '../input/styles';
import { StyledSelect, DropdownContainer } from './styles';

const renderDropdown = ({
  input,
  index,
  options,
  placeholder,
  columns,
  multi,
  meta: { touched, error },
  disabled,
}) => (
  <div>
    <StyledSelect
      {...input}
      index={index}
      multi={multi}
      options={options}
      onChange={({ value }) => input.onChange(value)}
      onBlur={event => event.preventDefault()}
      value={options.find(({ value }) => value === input.value) || input.value}
      placeholder={placeholder}
      columns={columns}
      classNamePrefix="select"
      isDisabled={disabled}
    />
    <Error touched={touched} error={error} />
  </div>
);

const Dropdown = ({
  disabled,
  index,
  name,
  options,
  placeholder,
  columns,
  validate,
  multi,
  onChange,
  onBlur,
}) => (
  <DropdownContainer>
    <Field
      index={index}
      name={name}
      multi={multi}
      component={renderDropdown}
      options={options}
      columns={columns}
      validate={validate}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      onBlur={onBlur}
    />
  </DropdownContainer>
);

export default Dropdown;
