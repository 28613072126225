import React from 'react';
import { graphql } from 'gatsby';

import Header from '../header';
import Footer from '../footer';
import MetaTags from '../meta-tags';

import GlobalStyles from './styles';

const Layout = ({
  children,
  fullWrapper,
  globalElements: {
    footerBanner,
    footerCTAButtonLink,
    footerCTAButtonText,
    footerCTALeadIn,
    siteMap,
    supportHours,
    supportImage,
    supportPhoneNumber: defaultSupportPhoneNumber,
    topNav,
    topNavButtonLink: defaultTopNavButtonLink,
    topNavButtonText: defaultTopNavButtonText,
    topNavMobileCTA: defaultTopNavMobileCTA,
    topNavMobileCTALink: defaultTopNavMobileCTALink,
    ...metaDefaults
  },
  location,
  pageMeta: {
    supportPhoneNumber,
    topNavButtonText,
    topNavButtonLink,
    topNavMobileCTA,
    topNavMobileCTALink,
    ...metaData
  },
  social,
  spaceBelowHeader,
  showSupportImage,
}) => (
  <>
    <GlobalStyles spaceBelowHeader={spaceBelowHeader} />
    <MetaTags defaultMeta={metaDefaults} pageMeta={metaData} location={location} />
    <Header
      fullWrapper={fullWrapper}
      supportHours={supportHours}
      supportPhone={supportPhoneNumber}
      supportImage={showSupportImage && supportImage}
      topNav={topNav}
      topNavButtonLink={topNavButtonLink || defaultTopNavButtonLink}
      topNavButtonText={topNavButtonText || defaultTopNavButtonText}
      topNavMobileCTA={topNavMobileCTA || defaultTopNavMobileCTA}
      topNavMobileCTALink={topNavMobileCTALink || defaultTopNavMobileCTALink}
    />
    {children}
    {fullWrapper && (
      <Footer
        banner={footerBanner}
        ctaButtonLink={footerCTAButtonLink}
        ctaButtonText={footerCTAButtonText}
        ctaLeadIn={footerCTALeadIn}
        sitemap={siteMap}
        social={social}
        supportHours={supportHours}
        supportPhone={supportPhoneNumber || defaultSupportPhoneNumber}
      />
    )}
  </>
);

export default Layout;

export const query = graphql`
  fragment GlobalElements on ContentfulGlobalElements {
    id
    title
    defaultMetaImage {
      file {
        fileName
        url
      }
    }
    defaultMetaTitle
    defaultMetaDescription
    footerCTALeadIn {
      childMarkdownRemark {
        html
      }
    }
    footerBanner {
      file {
        url
      }
    }
    footerCTAButtonLink
    footerCTAButtonText
    siteMap {
      id
      items {
        id
        externalLink
        slug
        title
      }
    }
    supportHours
    supportImage {
      file {
        fileName
        url
      }
    }
    supportPhoneNumber
    topNav {
      id
      title
      slug
      externalLink
    }
    topNavButtonLink
    topNavButtonText
    topNavMobileCTA
    topNavMobileCTALink
    twitterUsername
  }
`;
