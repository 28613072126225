import styled from 'styled-components';

import {
  COLORS,
  LINE_HEIGHT,
  SPACING,
  TYPE_SCALE,
  BORDER_WIDTH,
  BORDER_RADIUS,
  FONT_WEIGHT,
  TRANSITIONS,
} from 'clark-styles';

import { CardElement } from 'react-stripe-elements';

const { WHITE, CLARK_PRIMARY, GREY_25, GREY_100 } = COLORS;
const { S_025, S_05, S_1 } = SPACING;
const { BR_2 } = BORDER_RADIUS;
const { TS_4 } = TYPE_SCALE;
const { FW_100 } = FONT_WEIGHT;
const { BW_1 } = BORDER_WIDTH;
const { SOLID } = LINE_HEIGHT;
const { EASE_IN_OUT_25 } = TRANSITIONS;
const ERROR_PRIMARY = '#fcece5';

export const CollectCardElement = styled(CardElement)`
  ${TS_4};
  ${FW_100};
  ${BR_2};
  ${SOLID};
  ${EASE_IN_OUT_25};
  border: ${BW_1} solid ${({ showError }) => (showError ? CLARK_PRIMARY : GREY_25)};
  color: ${({ disabled }) => (disabled ? GREY_25 : GREY_100)};
  display: block;
  padding: calc(${S_025} + ${S_05});
  position: relative;
  outline: none;
  width: 100%;
  background-color: ${({ showError }) => (showError ? ERROR_PRIMARY : WHITE)};
  margin-bottom: ${S_05};

  &::placeholder {
    color: ${GREY_25};
  }
`;
export const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '20px',
      fontWeight: 100,
      color: GREY_100,
    },
    invalid: {},
  },
};
export const Label = styled.div`
  color: ${GREY_100};
  margin-bottom: ${S_025};
`;
export const CardInfoContainer = styled.div`
  margin-top: ${S_1};
`;
