import { createGlobalStyle } from 'styled-components';
import Reset from 'styled-reset';
import { COLORS, SPACING } from 'clark-styles';

import { FontPaths, FONTS, FONT_STYLE, FONT_WEIGHT } from '../../shared/typography';

const {
  GraphikRegular,
  GraphikRegularItalic,
  GraphikMedium,
  GraphikSemibold,
  GraphikSemiboldItalic,
  GraphikBold,
  VerlagBlack,
} = FontPaths;

const { GREY_3, GREY_100 } = COLORS;
const { PRIMARY_FONT } = FONTS;
const { FS_ITALIC } = FONT_STYLE;
const { FW_NORMAL, FW_MEDIUM, FW_SEMIBOLD, FW_BOLD, FW_BLACK } = FONT_WEIGHT;
const { S_4, S_8 } = SPACING;

const GlobalStyles = createGlobalStyle`
  ${Reset}

  @font-face {
    font-family: 'Graphik';
    font-style: normal;
    ${FW_NORMAL};
    src: url(${GraphikRegular}) format("woff");
  }

  @font-face {
    font-family: 'Graphik';
    ${FS_ITALIC};
    ${FW_NORMAL};
    src: url(${GraphikRegularItalic}) format("woff");
  }

  @font-face {
    font-family: 'Graphik';
    font-style: normal;
    ${FW_MEDIUM};
    src: url(${GraphikMedium}) format("woff");
  }

  @font-face {
    font-family: 'Graphik';
    font-style: normal;
    ${FW_SEMIBOLD};
    src: url(${GraphikSemibold}) format("woff");
  }

  @font-face {
    font-family: 'Graphik';
    ${FS_ITALIC};
    ${FW_SEMIBOLD};
    src: url(${GraphikSemiboldItalic}) format("woff");
  }

  @font-face {
    font-family: 'Graphik';
    font-style: normal;
    ${FW_BOLD};
    src: url(${GraphikBold}) format("woff");
  }

  @font-face {
    font-family: 'Verlag';
    font-style: normal;
    ${FW_BLACK};
    src: url(${VerlagBlack}) format("woff");
  }

  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    background-color: ${GREY_3};
    color: ${GREY_100};
    ${PRIMARY_FONT};
    padding-top: ${({ spaceBelowHeader }) => (spaceBelowHeader ? S_8 : S_4)};
  }

  a {
    -webkit-tap-highlight-color: transparent;
  }

  strong {
    ${FW_SEMIBOLD};
  }
`;

export default GlobalStyles;
