import styled from 'styled-components';

import { BORDER_RADIUS, COLORS, SPACING, FONT_WEIGHT, Row, Col, CapsLabel } from 'clark-styles';

const { S_025, S_05, S_1, S_2 } = SPACING;
const { BR_2 } = BORDER_RADIUS;
const { GREY_10, GREY_100, WHITE, CLARK_SECONDARY } = COLORS;
const { FW_500 } = FONT_WEIGHT;

export const MatchesContainer = styled(Row)`
  margin: 0;
`;

export const OptionCard = styled(Col)`
  background-color: ${WHITE};
  box-shadow: 10px 10px 15px -15px rgba(0, 0, 0, 0.2);
  border: solid 1px ${GREY_10};
  ${BR_2};
  padding: ${S_2} ${S_1};
  display: flex;
  flex-direction: column;
  align-self: stretch;
`;

export const DescriptorTitle = styled.div`
  ${FW_500};
  text-align: center;
`;

export const IconContainer = styled.div`
  color: ${CLARK_SECONDARY};
  margin-right: ${S_05};
`;

export const LargeIconContainer = styled.div`
  text-align: center;
  padding: ${S_2} 0;
`;

export const BenefitsContainer = styled.div`
  flex-grow: 1;
  margin-bottom: calc(${S_1} + ${S_025});
`;

export const RateText = styled(CapsLabel)`
  color: ${GREY_100};
  margin-left: ${S_025};
`;

export const Container = styled.div`
  margin: ${S_1} 0;
`;
