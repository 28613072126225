import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { graphql, navigate } from 'gatsby';
import { Grid, Row, Col } from 'clark-styles';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { connect } from 'react-redux';

import { trackEvent } from '../../shared/analytics';
import initStripe from '../../shared/stripe';

import { setFormValues, clearErrorMessage } from '../../state/actions';

import Icon from '../icon';
import SectionWrapper from '../section-wrapper';
import WYSIWYGContent from '../wysiwyg-content';
import Form from './form';
import Matches from '../matches';

import {
  BUILD_APPLICATION_FORM_TYPE,
  FIND_A_TUTOR_FORM_TYPE,
  MATCHING_OPTIONS_FORM_TYPE,
} from './constants';
import {
  BackButton,
  FormHeading,
  FormSubheader,
  FormErrorSubheader,
  RightRail,
  CenteredFormHeading,
  PromoCodeLabel,
  PromoCodeInput,
} from './styles';

const mapStateToProps = state => {
  const {
    state: { trackedFormValues, locationData },
  } = state;
  return { trackedFormValues, locationData };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchSetFormValues: formValues => dispatch(setFormValues(formValues)),
    dispatchClearErrorMessage: () => dispatch(clearErrorMessage()),
  };
};

const headerFormatter = (header, formType, formValues, locationData) => {
  switch (formType) {
    case FIND_A_TUTOR_FORM_TYPE: {
      const subjectText = formValues.subject ? `${formValues.subject} tutors` : 'tutors';
      const locationText = locationData ? ` in ${locationData.CITY}, ${locationData.STATE}` : '';

      return header.replace('[SUBJECT TUTORS]', subjectText).replace('[LOCATION]', locationText);
    }
    default:
      return header;
  }
};
const CollectionForm = ({
  data: {
    heading,
    subheader,
    steps,
    formType,
    formLayout,
    formSupporter,
    trackingDescription,
    hasSubmitButton,
    formContinuationLink,
  },
  trackedFormValues,
  locationData,
  location,
  dispatchSetFormValues,
  dispatchClearErrorMessage,
}) => {
  const [currentStep, setStep] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [header, setHeader] = useState(heading);
  const [subHeaderText, setSubHeaderText] = useState(subheader);
  dispatchClearErrorMessage();

  let stripe = null;
  if (formType === BUILD_APPLICATION_FORM_TYPE) {
    const incompleteContactInfo =
      !trackedFormValues.first_name || !trackedFormValues.last_name || !trackedFormValues.Email;
    // not defined at build and navigate uses window under the hood (A.L. 7/8/2019)
    const isWindowDefined = typeof window !== 'undefined';
    if (isWindowDefined && hasSubmitButton && incompleteContactInfo) {
      navigate('/build-application-form/', {
        state: { errorMessage: 'Oops! We need some more information.' },
      });
    }
    stripe = initStripe();
  }
  const isMatchingView = formType === MATCHING_OPTIONS_FORM_TYPE;

  const goBack = event => {
    event.preventDefault();
    setStep(currentStep - 1);
    trackEvent('Form Back Button Clicked');
  };

  const selectAnswer = (question, buttonText, buttonLink) => {
    if (!buttonLink) setStep(currentStep + 1);
    trackEvent('Form Button Clicked', { question, response: buttonText });
  };

  const storePromoCode = ({ target }) => {
    dispatchSetFormValues({ ...trackedFormValues, promoCode: target.value });
  };

  const rail = formLayout === 'Right rail' || formLayout === 'Left rail';
  const railContent = formSupporter ? (
    <WYSIWYGContent>
      {ReactHtmlParser(formSupporter.childMarkdownRemark.html)}
      {hasSubmitButton && (
        <>
          <PromoCodeLabel htmlFor="promo-code">Promo Code</PromoCodeLabel>
          <PromoCodeInput id="promo-code" name="promo-code" type="text" onBlur={storePromoCode} />
        </>
      )}
    </WYSIWYGContent>
  ) : (
    ''
  );

  const confirm = () => {
    setShowConfirmation(true);
    // move the confirmation to the middle of the page (A.L. - 7/1/2019)
    const isWindowDefined = typeof window !== 'undefined';
    if (isWindowDefined) {
      const top =
        document.getElementsByClassName('collectionForm')[0].offsetTop - window.innerHeight / 2;
      window.scrollTo(0, top);
    }
  };

  return (
    <StripeProvider stripe={stripe}>
      <SectionWrapper spaceBelow="XL">
        <Grid>
          <Row className="collectionForm">
            {formLayout === 'Left rail' && (
              <Col sm={12} md={6} lg={6}>
                {railContent}
              </Col>
            )}
            {!showConfirmation && (
              <Col
                sm={12}
                md={rail ? 6 : 8}
                mdOffset={rail ? 0 : 2}
                lg={formLayout === 'Left rail' ? 5 : 6}
                lgOffset={rail ? 1 : 3}
              >
                <BackButton href="" step={currentStep} onClick={e => goBack(e)}>
                  <Icon name="arrowLeft" size={16} viewbox="0 0 18 18" /> Back
                </BackButton>
                <FormHeading element="h1" level={3}>
                  {headerFormatter(header, formType, trackedFormValues, locationData)}
                </FormHeading>
                {location.state && location.state.errorMessage && (
                  <FormErrorSubheader element="h1" level={3}>
                    {location.state.errorMessage}
                  </FormErrorSubheader>
                )}
                <FormSubheader element="h1" level={3}>
                  {subHeaderText}
                </FormSubheader>
                {!isMatchingView && (
                  <Elements>
                    <Form
                      steps={steps}
                      selectAnswer={selectAnswer}
                      currentStep={currentStep}
                      formType={formType}
                      trackingDescription={trackingDescription}
                      hasSubmitButton={hasSubmitButton}
                      formContinuationLink={formContinuationLink}
                      stripe={stripe}
                      confirm={confirm}
                    />
                  </Elements>
                )}
                {isMatchingView && (
                  <Matches setHeader={setHeader} setSubheader={setSubHeaderText} />
                )}
              </Col>
            )}
            {showConfirmation && (
              <Col sm={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                <CenteredFormHeading element="h1" level={3}>
                  Sent! We&apos;ll be in touch.
                </CenteredFormHeading>
              </Col>
            )}
            {formLayout === 'Right rail' && (
              <Col sm={12} md={6} lg={4} lgOffset={1}>
                <RightRail>{railContent}</RightRail>
              </Col>
            )}
          </Row>
        </Grid>
      </SectionWrapper>
    </StripeProvider>
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CollectionForm);

export const query = graphql`
  fragment CollectionForm on ContentfulPageCollectionForm {
    id
    __typename
    heading
    subheader
    formType
    trackingDescription
    hasSubmitButton
    formContinuationLink
    formLayout
    formSupporter {
      childMarkdownRemark {
        html
      }
    }
    steps {
      id
      question
      inputType
      characterLimit
      isRequired
      propertyName
      placeholder
      options {
        label
        value
        link
        isDate
      }
    }
  }
`;
