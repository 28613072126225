export const areAnalyticsLoaded = () => typeof window !== 'undefined' && !!window.analytics;

export const getUserId = () =>
  areAnalyticsLoaded()
    ? window.analytics.user().id() || window.analytics.user().anonymousId()
    : null;

export const trackConversion = e => {
  const query = window.location.search || '';
  e.target.href += query;
};

export const trackEvent = (eventname, props) => {
  if (areAnalyticsLoaded()) {
    window.analytics.track(eventname, props);
  }
};

export const trackUser = (firstName, lastName, email, phone) => {
  const id = getUserId();
  if (id) {
    window.analytics.alias(id);
    window.analytics.identify(id, { firstName, lastName, email, phone });
  }
};
