import styled, { css } from 'styled-components';
import {
  BORDER_RADIUS,
  BORDER_WIDTH,
  BOX_SHADOW,
  COLORS,
  LETTER_SPACING,
  LINE_HEIGHT,
  SPACING,
  TRANSITIONS,
  TYPE_SCALE,
} from 'clark-styles';

import { FONTS, FONT_WEIGHT } from '../../shared/typography';

import Link from '../link';

const { CLARK_PRIMARY, CLARK_SECONDARY, GREY_10, GREY_25, GREY_75, GREY_100, WHITE } = COLORS;
const { BW_1 } = BORDER_WIDTH;
const { BR_2 } = BORDER_RADIUS;
const { BS_SMALL } = BOX_SHADOW;
const { SECONDARY_FONT } = FONTS;
const { FW_SEMIBOLD } = FONT_WEIGHT;
const { LS_1, LS_2_5 } = LETTER_SPACING;
const { SOLID } = LINE_HEIGHT;
const { S_1, S_2 } = SPACING;
const { EASE_IN_OUT_25 } = TRANSITIONS;
const { TS_6 } = TYPE_SCALE;

const CLARK_PRIMARY_ALT = '#ff5000';
const CLARK_SECONDARY_ALT = '#00c9c3';

const primary = css`
  background-color: ${CLARK_PRIMARY};
  border-color: ${CLARK_PRIMARY};
  box-shadow: 15px 18px 15px -15px rgba(234, 73, 0, 0.3);
  color: ${WHITE};
  ${SECONDARY_FONT};
  ${LS_2_5};

  &:hover {
    background-color: ${CLARK_PRIMARY_ALT};
    box-shadow: 15px 20px 20px -15px rgba(234, 73, 0, 0.35);
  }
`;

const secondary = css`
  background-color: ${CLARK_SECONDARY};
  border-color: ${CLARK_SECONDARY};
  box-shadow: 15px 18px 15px -15px rgba(0, 192, 186, 0.3);
  color: ${WHITE};
  ${SECONDARY_FONT};
  ${LS_2_5};

  &:hover {
    background-color: ${CLARK_SECONDARY_ALT};
    box-shadow: 15px 20px 20px -15px rgba(0, 192, 186, 0.35);
  }
`;

const tertiary = css`
  background-color: ${WHITE};
  border-color: ${GREY_10};
  ${BS_SMALL};
  color: ${GREY_100};
  ${SECONDARY_FONT};
  ${LS_2_5};

  &:hover {
    box-shadow: 15px 20px 20px -15px rgba(160, 158, 175, 0.35);
  }
`;

const outlined = css`
  background-color: ${WHITE};
  border-color: ${GREY_25};
  color: ${GREY_75};
  ${FW_SEMIBOLD};
  ${LS_1};

  &:hover {
    border-color: ${CLARK_SECONDARY};
  }
`;

const buttonStyleType = {
  primary: () => primary,
  secondary: () => secondary,
  tertiary: () => tertiary,
  outlined: () => outlined,
};

const disabledButton = css`
  background-color: ${GREY_25};
  border-color: ${GREY_25};
  color: ${WHITE};
  cursor: auto;
  box-shadow: none;

  &:hover {
    background-color: ${GREY_25};
    box-shadow: none;
    transform: none;
  }
`;

const CommonStyle = css`
  ${BR_2};
  border-style: solid;
  border-width: ${BW_1};
  cursor: pointer;
  display: block;
  ${TS_6};
  ${SOLID};
  margin: ${({ margin }) => margin};
  padding: ${S_1} ${S_2};
  position: relative;
  text-align: center;
  text-transform: uppercase;
  ${EASE_IN_OUT_25};
  width: 100%;
  ${({ styletype }) => buttonStyleType[styletype]};

  &:hover {
    transform: translate(0, -2px);
  }

  ${({ disabled }) => (disabled ? disabledButton : '')};
`;

export const ButtonStyle = styled.button`
  ${CommonStyle};
  outline: 0;
`;

export const ButtonLink = styled(Link)`
  ${CommonStyle};
  text-decoration: none;
`;
