import styled from 'styled-components';
import {
  Col,
  BOX_SHADOW,
  COLORS,
  LINE_HEIGHT,
  MEDIA,
  SPACING,
  TRANSITIONS,
  TYPE_SCALE,
} from 'clark-styles';

import { FONT_WEIGHT } from '../../shared/typography';

const { BS_SMALL } = BOX_SHADOW;
const { CLARK_PRIMARY, CLARK_SECONDARY, PURPLE, WHITE, YELLOW } = COLORS;
const { FW_SEMIBOLD } = FONT_WEIGHT;
const { COPY, TITLE } = LINE_HEIGHT;
const { S_05, S_1, S_2 } = SPACING;
const { EASE_IN_OUT_25 } = TRANSITIONS;
const { TS_3, TS_5 } = TYPE_SCALE;
const TS_7 = 'font-size: 0.75rem';

const background = {
  green: () => CLARK_SECONDARY,
  purple: () => PURPLE,
  red: () => CLARK_PRIMARY,
  yellow: () => YELLOW,
};

export const StyledCol = styled(Col)`
  margin-bottom: ${S_2};
  position: relative;
  ${EASE_IN_OUT_25};

  &:hover {
    transform: translate(0, -2px);
  }
`;

export const Summary = styled.div`
  background-color: ${({ backgroundColor }) => background[backgroundColor]};
  ${BS_SMALL};
  color: ${WHITE};
  ${COPY};
  ${FW_SEMIBOLD};
  height: 100%;
  opacity: 0;
  padding: ${S_05} ${S_1};
  position: absolute;
  top: 0;
  ${EASE_IN_OUT_25};
  width: 100%;
  ${MEDIA.medium`
    padding: ${S_1};
  `};

  &:hover {
    opacity: 1;
  }

  p {
    ${TS_7};
    margin-bottom: ${S_05};
    ${MEDIA.medium`
      ${TS_5};
    `};
  }

  .large-text {
    ${TS_3};
    ${TITLE};
  }
`;
