import styled, { css } from 'styled-components';
import {
  BORDER_WIDTH,
  BORDER_RADIUS,
  COLORS,
  FONT_WEIGHT,
  LINE_HEIGHT,
  SPACING,
  TYPE_SCALE,
  TRANSITIONS,
} from 'clark-styles';

const { WHITE, CLARK_PRIMARY, GREY_25, GREY_100 } = COLORS;
const { S_025, S_05 } = SPACING;
const { BR_2 } = BORDER_RADIUS;
const { TS_4, TS_6 } = TYPE_SCALE;
const { FW_100 } = FONT_WEIGHT;
const { BW_1 } = BORDER_WIDTH;
const { SOLID } = LINE_HEIGHT;
const { EASE_IN_OUT_25 } = TRANSITIONS;

export const ERROR_PRIMARY = '#fcece5';

export const InputContainer = styled.div`
  ${EASE_IN_OUT_25};
  align-items: center;
  position: relative;
  display: flex;
  margin-bottom: ${({ showError }) => (showError ? 0 : S_05)};
`;

export const FormInputStyle = css`
  ${TS_4};
  ${FW_100};
  ${BR_2};
  ${SOLID};
  ${EASE_IN_OUT_25};
  border: ${BW_1} solid ${({ showError }) => (showError ? GREY_25 : CLARK_PRIMARY)};
  color: ${({ disabled }) => (disabled ? GREY_25 : GREY_100)};
  display: block;
  padding: calc(${S_025} + ${S_05});
  position: relative;
  outline: none;
  width: 100%;
  background-color: ${({ showError }) => (showError ? WHITE : ERROR_PRIMARY)};

  &::placeholder {
    color: ${GREY_25};
  }
`;
export const FormInput = styled.input`
  ${FormInputStyle}
`;
export const Error = styled.span`
  ${FW_100};
  ${TS_6};
  color: ${CLARK_PRIMARY};
`;
