import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { Grid } from 'clark-styles';

import { StickyWrapper, StyledCol, StyledLink, StyledRow } from './styles';

const Nav = ({ data: { items } }) => {
  const [sticky, setSticky] = useState(false);

  const handleScroll = () => {
    const isSticky = window.scrollY > 64;
    if (sticky !== isSticky) setSticky(!sticky);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  });

  return (
    <StickyWrapper sticky={sticky}>
      <Grid>
        <StyledRow>
          <StyledCol sm={12} md={3} lg={2}>
            <ul>
              {items.map(({ id, externalLink, slug, title }) => (
                <li key={id}>
                  <StyledLink to={slug ? `/${slug}/` : externalLink}>{title}</StyledLink>
                </li>
              ))}
            </ul>
          </StyledCol>
        </StyledRow>
      </Grid>
    </StickyWrapper>
  );
};

export default Nav;

export const query = graphql`
  fragment Navigation on ContentfulNavigation {
    id
    __typename
    items {
      externalLink
      id
      slug
      title
    }
  }
`;
