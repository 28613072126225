import styled, { css } from 'styled-components';
import { COLORS, LINE_HEIGHT, SPACING, TYPE_SCALE } from 'clark-styles';

import ARROW from '../../shared/assets/arrow.svg';
import CHECKMARK from '../../shared/assets/checkmark.svg';
import { FONT_WEIGHT } from '../../shared/typography';

const { CLARK_SECONDARY } = COLORS;
const { FW_MEDIUM, FW_SEMIBOLD } = FONT_WEIGHT;
const { COPY, TITLE } = LINE_HEIGHT;
const { S_1, S_2 } = SPACING;
const { TS_3, TS_4 } = TYPE_SCALE;

const ARROW_SIZE = '1.5rem';

const headerStyles = css`
  ${FW_SEMIBOLD};
  ${TITLE};
  margin-bottom: ${S_1};
`;

const StyledContent = styled.div`
  h2 {
    ${TS_3};
    ${headerStyles};
  }

  h3 {
    ${headerStyles};
  }

  p {
    ${COPY};
    margin-bottom: ${S_1};
  }

  a {
    color: ${CLARK_SECONDARY};
    ${FW_MEDIUM};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ol {
    ${COPY};
    list-style-type: decimal;

    li {
      margin-bottom: ${S_1};
      margin-left: ${S_1};
    }
  }

  ul {
    ${COPY};

    li {
      margin-bottom: ${S_1};
      padding-left: ${ARROW_SIZE};
      position: relative;

      &::before {
        background-image: url(${CHECKMARK});
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        display: inline-block;
        height: ${S_1};
        left: 0;
        position: absolute;
        width: ${S_1};
      }
    }
  }

  del {
    position: relative;
    text-decoration: none;

    &::before {
      background-image: url(${ARROW});
      background-repeat: no-repeat;
      background-size: contain;
      color: ${CLARK_SECONDARY};
      content: '';
      ${FW_SEMIBOLD};
      height: ${ARROW_SIZE};
      left: -${S_2};
      position: absolute;
      top: 0;
      width: ${ARROW_SIZE};
    }
  }

  .large-text {
    ${TS_4};
  }
`;

export default StyledContent;
