import styled, { css } from 'styled-components';
import { LINE_HEIGHT, MEDIA, SPACING, TYPE_SCALE } from 'clark-styles';

import { FONT_WEIGHT } from '../../shared/typography';

const { FW_BOLD, FW_SEMIBOLD } = FONT_WEIGHT;
const { TITLE } = LINE_HEIGHT;
const { S_05, S_1, S_2 } = SPACING;
const { TS_1, TS_2, TS_3 } = TYPE_SCALE;

const h1 = css`
  ${TS_2};
  ${FW_BOLD};
  ${TITLE};
  margin-bottom: ${({ marginBottom }) => marginBottom || S_1};
  ${MEDIA.small`
    ${TS_1};
  `};
  ${MEDIA.large`
    margin-bottom: ${({ marginBottom }) => marginBottom || S_2};
  `};
`;

const h2 = css`
  ${TS_2};
  ${FW_BOLD};
  ${TITLE};
  margin-bottom: ${({ marginBottom }) => marginBottom || S_1};
`;

const h3 = css`
  ${TS_3};
  ${FW_SEMIBOLD};
  ${TITLE};
  margin-bottom: ${({ marginBottom }) => marginBottom || S_05};
`;

const h4 = css`
  ${FW_SEMIBOLD};
`;

const headingLevel = {
  1: () => h1,
  2: () => h2,
  3: () => h3,
  4: () => h4,
};

const StyledHeading = styled.p`
  ${({ level }) => headingLevel[level]};
  ${({ centered }) => centered && 'text-align: center'};
`;

export default StyledHeading;
