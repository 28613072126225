import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Grid, Row, Col, SPACING } from 'clark-styles';

import { trackConversion, trackEvent } from '../../shared/analytics';

import Button from '../button';
import HeroInput from '../hero-input';
import HeroDropdown from '../hero-input/dropdown';
import HeadingElement from '../heading-element';
import Link from '../link';
import SectionWrapper from '../section-wrapper';
import UtilityText from '../utility-text';

import { Container, ImageCol, LogoGrid, LogoGridItem, LogoGridImage, Summary } from './styles';
import { fetchMasterSubjects } from '../../shared/api';

const { S_2 } = SPACING;

const Hero = ({
  data: {
    heading,
    heroSummary,
    image: { title: altName, fluid: heroImage },
    formFieldLabel,
    formFieldName,
    formFieldPlaceholder,
    buttonLink,
    buttonText,
    featuredPress,
    spaceBelow,
  },
  location,
}) => {
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    fetchMasterSubjects().then(allSubjects => {
      const formattedSubjects = allSubjects.map(({ id, name }) => ({ value: id, label: name }));
      setSubjects(formattedSubjects);
    });
  }, []);
  const isSubjectDropdown =
    formFieldName &&
    (formFieldName.toLowerCase() === 'subjectId' || formFieldName.toLowerCase() === 'subject');

  const fieldElement = isSubjectDropdown ? (
    <HeroDropdown
      buttonText={buttonText}
      inputLabel={formFieldLabel}
      inputName="subjectId"
      inputPlaceholder={formFieldPlaceholder}
      buttonLink={buttonLink}
      location={location}
      options={subjects}
    />
  ) : (
    <HeroInput
      buttonText={buttonText}
      inputLabel={formFieldLabel}
      inputName={formFieldName}
      inputPlaceholder={formFieldPlaceholder}
      buttonLink={buttonLink}
      location={location}
    />
  );
  return (
    <SectionWrapper spaceBelow={spaceBelow}>
      <Container>
        <Grid>
          <Row>
            <Col sm={12} md={6} lg={5}>
              <HeadingElement element="h1" level={1}>
                {heading}
              </HeadingElement>
              {formFieldPlaceholder && fieldElement}
              {!formFieldPlaceholder && (
                <Button
                  margin={`${S_2} 0`}
                  onClick={e => {
                    trackConversion(e);
                    trackEvent('Hero Module Button Clicked', {
                      action: buttonLink,
                      buttonText: buttonText || 'Get started',
                      category: 'hero unit',
                      label: buttonText || 'Get started',
                    });
                  }}
                  path={buttonLink}
                >
                  {buttonText || 'Get started'}
                </Button>
              )}
              {heroSummary && <Summary>{heroSummary}</Summary>}
              {featuredPress && (
                <>
                  <UtilityText>Featured in</UtilityText>
                  <LogoGrid>
                    {featuredPress.map(
                      ({
                        id,
                        url,
                        outletLogo: {
                          title,
                          file: { url: fileUrl },
                        },
                      }) => (
                        <LogoGridItem key={id}>
                          <Link to={url}>
                            <LogoGridImage src={fileUrl} alt={title} />
                          </Link>
                        </LogoGridItem>
                      ),
                    )}
                  </LogoGrid>
                </>
              )}
            </Col>
            <ImageCol sm={12} md={6} lg={7}>
              <Img fluid={heroImage} alt={altName} />
            </ImageCol>
          </Row>
        </Grid>
      </Container>
    </SectionWrapper>
  );
};

export default Hero;

export const query = graphql`
  fragment Hero on ContentfulPageHero {
    id
    __typename
    heading
    heroSummary: summary
    image {
      title
      fluid(maxWidth: 810) {
        aspectRatio
        src
        srcSet
        sizes
      }
    }
    formFieldLabel
    formFieldName
    formFieldPlaceholder
    buttonLink
    buttonText
    featuredPress {
      id
      outletLogo {
        title
        file {
          url
        }
      }
      url
    }
    spaceBelow
  }
`;
