// @flow

import React from 'react';
import { Field } from 'redux-form';
import { Flex } from 'clark-styles';
import RadioButton from './radio-button';

const onClickHandler = ({ onChange }, isSelected, value) => {
  const newValue = isSelected ? null : value;
  onChange(newValue);
};

const RadioButtonGroup = ({ values, index, input }) => {
  return (
    <Flex flexDirection="column">
      {values.map(({ value, label: buttonLabel }) => (
        <RadioButton
          key={value}
          index={index}
          input={input}
          isSelected={value === input.value}
          label={buttonLabel}
          name={input.name}
          onClickHandler={onClickHandler}
          value={value}
        />
      ))}
    </Flex>
  );
};

const RadioButtonField = props => <Field component={RadioButtonGroup} {...props} />;

export default RadioButtonField;
