import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Grid, Row, Col, Flex, SPACING } from 'clark-styles';

import { trackEvent } from '../../shared/analytics';

import Button from '../button';
import Icon from '../icon';
import Logo from '../logo';
import MailchimpForm from '../mailchimp-form';
import SupportCTA from '../support-cta';

import {
  FooterBanner,
  FooterElement,
  FooterLogo,
  CTA,
  FormWrapper,
  Nav,
  NavCol,
  NavItem,
  NavLink,
  SocialIcon,
  StyledGrid,
  StyledRow,
} from './styles';

const { S_1 } = SPACING;

const Footer = ({
  banner: {
    file: { url: bannerUrl },
  },
  ctaButtonLink,
  ctaButtonText,
  ctaLeadIn: {
    childMarkdownRemark: { html: ctaMarkup },
  },
  sitemap,
  social,
  supportHours,
  supportPhone,
}) => (
  <>
    <FooterBanner bgImage={bannerUrl} />
    <FooterElement>
      <Grid>
        <Row>
          <Col sm={12} md={5} lg={3}>
            <FooterLogo>
              <Logo />
            </FooterLogo>
            <CTA>
              {ReactHtmlParser(ctaMarkup)}
              <p>
                <Button
                  margin={`${S_1} 0 0`}
                  onClick={() =>
                    trackEvent('Footer CTA Button Clicked', { buttonText: ctaButtonText })
                  }
                  path={ctaButtonLink}
                  styletype="secondary"
                >
                  {ctaButtonText}
                </Button>
              </p>
            </CTA>
          </Col>
          <FormWrapper sm={12} md={6} mdOffset={1} lg={3}>
            <MailchimpForm />
          </FormWrapper>
          <Col sm={12} md={12} lg={6}>
            <StyledGrid>
              <StyledRow>
                {sitemap.map(({ id, items }, index) => (
                  <NavCol
                    key={id}
                    sm={6}
                    md={3}
                    lg={4}
                    lastCol={sitemap.length > 3 && index === sitemap.length - 1}
                  >
                    <Nav>
                      {items.map(({ externalLink, id: itemId, slug, title }) => (
                        <NavItem key={itemId}>
                          <NavLink to={slug ? `/${slug}/` : externalLink}>{title}</NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                  </NavCol>
                ))}
              </StyledRow>
            </StyledGrid>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={4}>
            <SupportCTA supportHours={supportHours} supportPhone={supportPhone} />
          </Col>
          <Col sm={8} smOffset={2} md={4} mdOffset={2} lg={2} lgOffset={6}>
            <Flex justifyContent="space-between">
              {social.map(platform => (
                <SocialIcon key={platform.name} to={platform.url}>
                  <Icon name={platform.name} />
                </SocialIcon>
              ))}
            </Flex>
          </Col>
        </Row>
      </Grid>
    </FooterElement>
  </>
);

export default Footer;
