import styled from 'styled-components';

import {
  BORDER_RADIUS,
  BORDER_WIDTH,
  BOX_SHADOW,
  COLORS,
  LETTER_SPACING,
  LINE_HEIGHT,
  MEDIA,
  SPACING,
  TYPE_SCALE,
} from 'clark-styles';

import { FONT_WEIGHT } from '../../shared/typography';

import Button from '../button';
import HeadingElement from '../heading-element';

const { S_025, S_05, S_1, S_2, S_4 } = SPACING;
const { BR_2 } = BORDER_RADIUS;
const { BW_1 } = BORDER_WIDTH;
const { BS_SMALL } = BOX_SHADOW;
const { GREY_10, GREY_25, GREY_100, WHITE, CLARK_PRIMARY } = COLORS;
const { TS_2, TS_4, TS_6 } = TYPE_SCALE;
const { COPY } = LINE_HEIGHT;
const { FW_MEDIUM, FW_SEMIBOLD } = FONT_WEIGHT;
const { LS_1 } = LETTER_SPACING;

export const BackButton = styled.a`
  color: ${GREY_100};
  ${TS_6};
  ${FW_SEMIBOLD};
  ${LS_1};
  text-decoration: none;
  text-transform: uppercase;
  visibility: ${({ step }) => (step > 0 ? 'visible' : 'hidden')};
`;

export const FormHeading = styled(HeadingElement)`
  margin-bottom: ${S_2};
  margin-top: ${S_1};
  ${MEDIA.small`
    ${TS_2};
    margin-bottom: ${S_1};
  `};
`;
export const CenteredFormHeading = styled(FormHeading)`
  text-align: center;
`;
export const FormSubheader = styled.div`
  color: ${GREY_100};
  margin-bottom: ${S_1};
`;

export const FormErrorSubheader = styled.div`
  color: ${CLARK_PRIMARY};
  margin-bottom: ${S_1};
`;

export const FormStep = styled.div`
  display: ${({ index, step }) => (index === step ? 'block' : 'none')};
  padding: ${S_05} 0;
`;

export const FormStepQuestion = styled.p`
  ${TS_4};
  ${FW_MEDIUM};
  ${COPY};
  margin-bottom: ${S_025};
`;

export const FormStepButton = styled(Button)`
  margin-bottom: ${S_1};
`;

export const SubmitButton = styled(Button)`
  margin: ${S_1} 0;
  text-transform: uppercase;
  width: 100%;
`;

export const RightRail = styled.div`
  background-color: ${WHITE};
  border: 1px solid ${GREY_10};
  ${BR_2};
  ${BS_SMALL};
  padding: ${S_2} calc(${S_1} + ${S_2});
  margin-top: ${S_4};
`;

export const PromoCodeLabel = styled.label`
  display: block;
  ${FW_SEMIBOLD};
  ${COPY};
  margin: ${S_1} 0 ${S_025};
`;

export const PromoCodeInput = styled.input`
  border: ${BW_1} solid ${GREY_25};
  ${BR_2};
  ${TS_4};
  font-weight: 100;
  outline: none;
  margin-bottom: ${S_1};
  padding: calc(${S_025} + ${S_05});
  width: 100%;
`;
