import Select from 'react-select';
import styled from 'styled-components';
import { BORDER_WIDTH, BORDER_RADIUS, COLORS, FONT_WEIGHT, TYPE_SCALE } from 'clark-styles';

const { CLARK_PRIMARY, CLARK_SECONDARY, GREY_25, WHITE, GREY_100, GREY_10 } = COLORS;
const { BORDER_RADIUS_F2 } = BORDER_RADIUS;
const { TYPE_SCALE_F4 } = TYPE_SCALE;
const { FONT_WEIGHT_100, FONT_WEIGHT_400 } = FONT_WEIGHT;
const { BW_1 } = BORDER_WIDTH;
const INDICATOR_SPACING = '0.75rem'; // 12px;

export const DropdownContainer = styled.div`
  display: block;
`;

export const StyledSelect = styled(Select)`
  ${FONT_WEIGHT_100};
  ${TYPE_SCALE_F4};
  background: ${WHITE};
  color: ${GREY_100};

  &:focus {
    outline: none !important;
  }

  .select__single-value {
    color: ${GREY_100};
  }

  .select__control {
    ${BORDER_RADIUS_F2};
    border: ${BW_1} solid ${({ showError }) => (showError ? CLARK_PRIMARY : GREY_25)};
    box-shadow: none !important;

    &:hover {
      border-color: ${GREY_25};
    }
  }

  .select__control--is-focused {
    border-color: ${GREY_25};
  }

  .select__indicator-separator {
    display: none;
  }

  .select__indicator {
    color: ${CLARK_SECONDARY} !important;
    padding: ${INDICATOR_SPACING};
  }

  .select__option--is-focused {
    background-color: ${GREY_10} !important;
  }

  .select__option--is-selected {
    background-color: ${CLARK_SECONDARY} !important;
    ${FONT_WEIGHT_400};
  }

  .select__menu {
    margin-top: 0;
  }
`;
