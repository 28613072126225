import React from 'react';

import StyledText from './styles';

const UtilityText = ({ children, className, color = 'tertiary', hasMarginBottom }) => (
  <StyledText className={className} color={color} hasMarginBottom={hasMarginBottom}>
    {children}
  </StyledText>
);

export default UtilityText;
