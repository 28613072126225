import React from 'react';
import Helmet from 'react-helmet';

const Facebook = ({ description, image, pageUrl, siteName, title }) => (
  <Helmet>
    <meta property="og:type" content="website" />
    {siteName && <meta property="og:site_name" content={siteName} />}
    {title && <meta property="og:title" content={title} />}
    {description && <meta property="og:description" content={description} />}
    {image && <meta property="og:image" content={image} />}
    {pageUrl && <meta property="og:url" content={pageUrl} />}
  </Helmet>
);

export default Facebook;
