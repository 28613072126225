import React from 'react';
import Helmet from 'react-helmet';

const Twitter = ({ cardType = 'summary_large_image', description, image, title, username }) => (
  <Helmet>
    <meta name="twitter:card" content={cardType} />
    {title && <meta name="twitter:title" content={title} />}
    {description && <meta name="twitter:description" content={description} />}
    {image && <meta name="twitter:image" content={image} />}
    {username && <meta name="twitter:creator" content={username} />}
  </Helmet>
);

export default Twitter;
