import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import { navigate } from 'gatsby';
import { trackEvent } from '../../shared/analytics';
import { setFormValues } from '../../state/actions';
import Button from '../button';
import Dropdown from '../dropdown';

import { Form, FormLabel, DropdownContainer } from './styles';

const mapStateToProps = state => {
  const { form } = state;
  const formValues = form.heroDropdown && form.heroDropdown.values;
  return { formValues };
};
const mapDispatchToProps = dispatch => {
  return {
    dispatchSetFormValues: formValues => dispatch(setFormValues(formValues)),
  };
};

const HeroDropdown = ({
  buttonText,
  inputLabel,
  inputName,
  inputPlaceholder,
  buttonLink,
  options,
  formValues,
  dispatchSetFormValues,
  location,
}) => {
  const defaultLink = '/demo-form/';

  const handleSubmit = () => {
    let formattedFormValues = { entryPath: location.pathname, ...formValues };
    if (formValues && formValues.subjectId) {
      const { subjectId } = formValues;
      const subject = options.find(x => x.value === subjectId).label;
      formattedFormValues = { entryPath: location.pathname, ...formValues, subject };
    }
    trackEvent(`${inputName} Submitted`, formValues);
    dispatchSetFormValues(formattedFormValues);
    navigate(buttonLink || defaultLink);
  };

  return (
    <Form>
      <FormLabel htmlFor={inputName}>{inputLabel}</FormLabel>
      <DropdownContainer>
        <Dropdown
          type="dropdown"
          name={inputName}
          options={options}
          placeholder={inputPlaceholder}
        />
      </DropdownContainer>
      <Button type="submit" onClick={handleSubmit}>
        {buttonText || 'Get started'}
      </Button>
    </Form>
  );
};
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'heroDropdown',
  }),
)(HeroDropdown);
