import React, { useEffect, useState } from 'react';
import { Grid, Row, Col, Flex, SPACING } from 'clark-styles';

import { trackConversion, trackEvent } from '../../shared/analytics';

import Logo from '../logo';

import {
  HeaderElement,
  HeaderButton,
  HeaderCTA,
  HeaderLogo,
  Nav,
  MenuButton,
  MenuButtonTop,
  MenuButtonMiddle,
  MenuButtonBottom,
  Menu,
  MenuScreen,
  MenuItems,
  MenuItem,
  MenuItemLink,
  MenuSupportCTA,
  MenuSupportImage,
  FormSupportCTA,
} from './styles';

const { S_4 } = SPACING;

const Header = ({
  fullWrapper,
  supportImage: {
    file: { url: supportImage },
  },
  supportHours,
  supportPhone,
  topNav,
  topNavButtonLink,
  topNavButtonText,
  topNavMobileCTA,
  topNavMobileCTALink,
}) => {
  const isWindowDefined = typeof window !== 'undefined';

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isScrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const scrollPos = window.scrollY > 100;
    if (scrollPos !== isScrolled) setScrolled(!isScrolled);
  };

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
    document.body.style.overflow = isMenuOpen ? 'visible' : 'hidden';
  };

  useEffect(() => {
    if (isWindowDefined && window.scrollY > 100) {
      setScrolled(true);
    }
    window.addEventListener('scroll', handleScroll);
  });

  return (
    <HeaderElement scrolled={isScrolled} showBg={isScrolled || !fullWrapper}>
      <Grid>
        <Row>
          <Col sm={12}>
            <Flex alignItems="center" height={S_4} justifyContent="space-between">
              <HeaderLogo>
                <Logo />
              </HeaderLogo>
              <HeaderCTA
                onClick={e => {
                  trackConversion(e);
                  trackEvent('Header Demo Link Clicked', { buttonText: topNavMobileCTA });
                }}
                to={topNavMobileCTALink}
              >
                {topNavMobileCTA}
              </HeaderCTA>
              {fullWrapper ? (
                <>
                  <Nav>
                    <MenuButton type="button" onClick={toggleMenu}>
                      <MenuButtonTop isMenuOpen={isMenuOpen} />
                      <MenuButtonMiddle isMenuOpen={isMenuOpen} />
                      <MenuButtonBottom isMenuOpen={isMenuOpen} />
                    </MenuButton>
                    <MenuScreen isMenuOpen={isMenuOpen} onClick={toggleMenu} />
                    <Menu isMenuOpen={isMenuOpen}>
                      <MenuItems scrolled={isScrolled && supportPhone}>
                        {topNav.map(item => (
                          <MenuItem key={item.id}>
                            <MenuItemLink to={item.externalLink || `/${item.slug}`}>
                              {item.title}
                            </MenuItemLink>
                          </MenuItem>
                        ))}
                      </MenuItems>
                      {isScrolled && supportPhone && <MenuSupportImage src={supportImage} alt="" />}
                      <MenuSupportCTA
                        scrolled={isScrolled && supportPhone}
                        supportHours={supportHours}
                        supportPhone={supportPhone}
                      />
                    </Menu>
                  </Nav>
                  {isScrolled && topNavButtonLink && !supportPhone && (
                    <HeaderButton
                      path={topNavButtonLink}
                      onClick={e => {
                        trackConversion(e);
                        trackEvent('Header Demo Button Clicked', {
                          buttonText: topNavButtonText,
                        });
                      }}
                      styletype="primary"
                    >
                      {topNavButtonText}
                    </HeaderButton>
                  )}
                </>
              ) : (
                <FormSupportCTA supportHours={supportHours} supportPhone={supportPhone} />
              )}
            </Flex>
          </Col>
        </Row>
      </Grid>
    </HeaderElement>
  );
};

export default Header;
