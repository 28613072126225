import styled from 'styled-components';
import { Grid, Row, Col, BOX_SHADOW, COLORS, LINE_HEIGHT, MEDIA, SPACING } from 'clark-styles';

import Link from '../link';

const { BS_SMALL } = BOX_SHADOW;
const { GREY_100, WHITE } = COLORS;
const { COPY } = LINE_HEIGHT;
const { S_1, S_2, S_4 } = SPACING;
const FooterBannerHeight = '12rem';

export const FooterBanner = styled.div`
  display: none;
  ${MEDIA.small`
    background: url('${({ bgImage }) => bgImage}') center no-repeat;
    background-size: auto 100%;
    display: block;
    height: ${FooterBannerHeight};
    width: 100%;
  `};
`;

export const FooterElement = styled.footer`
  background-color: ${WHITE};
  ${BS_SMALL};
  padding-bottom: ${S_4};
  padding-top: ${S_4};
`;

export const FooterLogo = styled.div`
  height: 30px;
  margin-bottom: ${S_1};
  width: 168px;
`;

export const CTA = styled.div`
  ${COPY};
  margin-bottom: ${S_2};
  ${MEDIA.small`
    margin-bottom: ${S_4};
  `};
`;

export const FormWrapper = styled(Col)`
  margin-bottom: ${S_4};
  ${MEDIA.medium`
    order: 3;
  `};
`;

export const Nav = styled.ul`
  margin-bottom: ${S_2};
  ${MEDIA.medium`
    margin-bottom: 0;
  `};
`;

export const NavCol = styled(Col)`
  ${MEDIA.medium`
    ${({ lastCol }) => lastCol && `margin-left: auto`};
  `};
`;

export const NavItem = styled.li`
  margin-bottom: ${S_1};
`;

export const NavLink = styled(Link)`
  color: ${GREY_100};
  text-decoration: none;
`;

export const SocialIcon = styled(Link)`
  color: ${COLORS.GREY_75};
`;

export const StyledGrid = styled(Grid)`
  padding: 0;
  ${MEDIA.small`
    padding: 0;
  `};
`;

export const StyledRow = styled(Row)`
  margin: 0;
`;
