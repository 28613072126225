import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Grid, Row, Col, SPACING } from 'clark-styles';

import { trackEvent } from '../../shared/analytics';

import HeadingElement from '../heading-element';
import Icon from '../icon';
import SectionWrapper from '../section-wrapper';

import { GridCol, GridImg, GridLink, LinkText, NestedGrid, Summary } from './styles';

const { S_05, S_1 } = SPACING;

const FeatureGrid = ({ data: { dividers, heading, items, spaceBelow } }) => (
  <SectionWrapper spaceBelow={spaceBelow}>
    <Grid>
      {heading && (
        <Row>
          <Col sm={12} md={12} lg={12}>
            <HeadingElement centered element="h2" level={1} marginBottom={S_1}>
              {heading}
            </HeadingElement>
          </Col>
        </Row>
      )}
      <Row alignItems="stretch">
        {items.map(({ highlightColor, id, icon, iconHover, linkText, linkUrl, summary, title }) => {
          const [isHovered, setHovered] = useState(false);
          const innerGrid = (
            <NestedGrid>
              <Row>
                <Col sm={8} smOffset={2} md={8} mdOffset={2} lg={12}>
                  {icon && (
                    <GridImg
                      src={iconHover && isHovered ? iconHover.fixed.src : icon.fixed.src}
                      alt={icon.title}
                    />
                  )}
                  <HeadingElement element="h3" level={3} marginBottom={S_05}>
                    {title}
                  </HeadingElement>
                  {summary && <Summary>{summary}</Summary>}
                  {linkText && (
                    <LinkText color={highlightColor}>
                      {linkText} <Icon name="arrowRight" size={18} viewbox="0 0 18 18" />
                    </LinkText>
                  )}
                </Col>
              </Row>
            </NestedGrid>
          );
          return (
            <GridCol key={id} sm={12} md={6} lg={4} dividers={dividers}>
              {linkUrl ? (
                <GridLink
                  background={highlightColor}
                  to={linkUrl}
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                  onClick={() => trackEvent('Feature Grid Link Clicked', { linkText })}
                >
                  {innerGrid}
                </GridLink>
              ) : (
                innerGrid
              )}
            </GridCol>
          );
        })}
      </Row>
    </Grid>
  </SectionWrapper>
);

export default FeatureGrid;

export const query = graphql`
  fragment FeatureGrid on ContentfulPageFeatureGrid {
    id
    __typename
    heading
    items {
      highlightColor
      id
      icon {
        fixed(height: 100, width: 100) {
          aspectRatio
          height
          src
          srcSet
          width
        }
        title
      }
      iconHover {
        fixed(height: 100, width: 100) {
          aspectRatio
          height
          src
          srcSet
          width
        }
        title
      }
      linkText
      linkUrl
      summary
      title
    }
    spaceBelow
  }
`;
