import React from 'react';
import { graphql } from 'gatsby';
import { Grid, Row, Col } from 'clark-styles';

import SectionWrapper from '../section-wrapper';

import StyledImg from './styles';

const Image = ({
  data: {
    dropShadow,
    fullBleed,
    image: { title: altName, fluid: fluidImage },
    spaceBelow,
  },
}) => (
  <SectionWrapper spaceBelow={spaceBelow}>
    {fullBleed ? (
      <StyledImg fullBleed fluid={fluidImage} alt={altName} shadow={dropShadow} />
    ) : (
      <Grid>
        <Row>
          <Col sm={12} md={9} mdOffset={3} lg={8} lgOffset={2}>
            <StyledImg fluid={fluidImage} alt={altName} shadow={dropShadow} />
          </Col>
        </Row>
      </Grid>
    )}
  </SectionWrapper>
);

export default Image;

export const query = graphql`
  fragment Image on ContentfulPageImage {
    id
    __typename
    dropShadow
    fullBleed
    image {
      title
      fluid(maxWidth: 2000) {
        aspectRatio
        src
        srcSet
        sizes
      }
    }
    spaceBelow
  }
`;
