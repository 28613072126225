import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import { trackEvent } from '../../shared/analytics';

import Button from '../button';

import { Form, FormLegend, FormLabel, FormInput, FormResponse } from './styles';

const MailchimpForm = () => {
  const [email, setEmail] = useState('');
  const [submitMessage, setSubmitMessage] = useState('');
  const [submitResult, setSubmitResult] = useState('');

  const handleInputChange = ({ target }) => {
    setEmail(target.value);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const response = await addToMailchimp(email);
    const { result, msg } = response;
    setSubmitMessage(msg);
    setSubmitResult(result);
    if (result === 'success') trackEvent('Mailchimp Signup Form Submitted', { email });
  };

  return (
    <>
      <Form onSubmit={handleSubmit} submitted={submitResult}>
        <FormLegend>Sign up for the latest news</FormLegend>
        <FormLabel htmlFor="email">
          Email address
          <FormInput
            type="email"
            name="email"
            placeholder="Email address"
            onChange={handleInputChange}
          />
        </FormLabel>
        <Button type="submit" styletype="outlined">
          Subscribe
        </Button>
      </Form>
      <FormResponse>{ReactHtmlParser(submitMessage)}</FormResponse>
    </>
  );
};

export default MailchimpForm;
