import React from 'react';

import { ButtonStyle, ButtonLink } from './styles';

const Button = ({
  children,
  className,
  onClick,
  margin = 0,
  path,
  styletype = 'primary',
  type = 'submit',
  disabled = false,
}) => (
  <>
    {path ? (
      <ButtonLink
        className={className}
        margin={margin}
        onClick={onClick}
        styletype={styletype}
        to={path}
        disabled={disabled}
      >
        {children}
      </ButtonLink>
    ) : (
      <ButtonStyle
        className={className}
        margin={margin}
        onClick={onClick}
        styletype={styletype}
        type={type}
        disabled={disabled}
      >
        {children}
      </ButtonStyle>
    )}
  </>
);

export default Button;
