import styled from 'styled-components';
import { Flex, COLORS, LINE_HEIGHT, MEDIA, SPACING } from 'clark-styles';

import Icon from '../icon';

const { GREY_75, GREY_100 } = COLORS;
const { COPY } = LINE_HEIGHT;
const { S_05, S_2 } = SPACING;

export const Support = styled(Flex)`
  color: ${GREY_75};
  ${COPY};
  margin-bottom: ${S_2};
  margin-top: ${S_2};
  ${MEDIA.small`
    margin-top: 0;
  `};
  ${MEDIA.medium`
    margin-bottom: 0;
  `};
`;

export const SupportIcon = styled(Icon)`
  color: ${GREY_100};
  margin-right: ${S_05};
`;

export const SupportPhoneNo = styled.a`
  color: ${GREY_75};
  text-decoration: none;
`;
