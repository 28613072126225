import React from 'react';
import { graphql } from 'gatsby';
import truncate from 'lodash/truncate';
import Img from 'gatsby-image';
import { Col } from 'clark-styles';

import HeadingElement from '../heading-element';
import Icon from '../icon';
import Link from '../link';
import SectionWrapper from '../section-wrapper';
import UtilityText from '../utility-text';

import { StyledGrid, StyledRow, PaddedCol, ShortSummary, LongSummary, SummaryLink } from './styles';

const truncateSummary = summaryText =>
  truncate(summaryText, {
    length: 120,
    separator: /,? +/,
  });

const Summary = ({ data: { title, summaryText, image, link, spaceBelow } }) => (
  <SectionWrapper spaceBelow={spaceBelow}>
    <StyledGrid>
      <StyledRow alignItems="center" link={link}>
        {image && (
          <Col sm={12} md={5} lg={3}>
            {link ? (
              <Link to={link}>
                <Img fluid={image.fluid} alt={image.title} />
              </Link>
            ) : (
              <Img fluid={image.fluid} alt={image.title} />
            )}
          </Col>
        )}
        <PaddedCol sm={12} md={6} lg={7}>
          <HeadingElement element="h3" level={3}>
            {title}
          </HeadingElement>
          {summaryText && (
            <>
              <ShortSummary>{truncateSummary(summaryText)}</ShortSummary>
              <LongSummary>{summaryText}</LongSummary>
            </>
          )}
          {link && (
            <UtilityText>
              <SummaryLink to={link}>
                Read more <Icon name="arrowRight" size={18} viewbox="0 0 18 18" />
              </SummaryLink>
            </UtilityText>
          )}
        </PaddedCol>
      </StyledRow>
    </StyledGrid>
  </SectionWrapper>
);

export default Summary;

export const query = graphql`
  fragment Summary on ContentfulPageSummary {
    id
    __typename
    title
    summaryText: summary
    image {
      id
      title
      fluid(maxWidth: 330, maxHeight: 265) {
        aspectRatio
        src
        srcSet
        sizes
      }
    }
    link
    spaceBelow
  }
`;
