import styled from 'styled-components';
import Img from 'gatsby-image';
import { BOX_SHADOW } from 'clark-styles';

const { BS_LARGE } = BOX_SHADOW;
const BANNER_HEIGHT = '460px';

const StyledImg = styled(Img)`
  ${({ shadow }) => shadow && BS_LARGE};
  ${({ fullBleed }) => fullBleed && `max-height: ${BANNER_HEIGHT}`};
`;

export default StyledImg;
