import styled from 'styled-components';
import { BORDER_RADIUS, BOX_SHADOW, COLORS, LINE_HEIGHT, SPACING, TYPE_SCALE } from 'clark-styles';

import { FONT_WEIGHT } from '../../shared/typography';

const { BR_2 } = BORDER_RADIUS;
const { BS_SMALL } = BOX_SHADOW;
const { GREY_75 } = COLORS;
const { FW_SEMIBOLD } = FONT_WEIGHT;
const { COPY } = LINE_HEIGHT;
const { S_05, S_1, S_2 } = SPACING;
const { TS_4 } = TYPE_SCALE;

export const Form = styled.div`
  margin: ${S_2} 0;
`;

export const FormLabel = styled.label`
  display: block;
  ${FW_SEMIBOLD};
  ${COPY};
  margin-bottom: ${S_05};
`;

export const FormInput = styled.input`
  border: 0;
  ${BR_2};
  ${BS_SMALL};
  color: ${GREY_75};
  ${TS_4};
  margin-bottom: ${S_1};
  padding: ${S_1} ${S_2};
  width: 100%;
`;

export const DropdownContainer = styled.div`
  margin-bottom: ${S_1};
`;
