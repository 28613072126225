import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Grid, Row, Col } from 'clark-styles';

import SectionWrapper from '../section-wrapper';
import WYSIWYGContent from '../wysiwyg-content';

import ImageCol from './styles';

const Paragraph = ({
  data: {
    image,
    spaceBelow,
    text: {
      childMarkdownRemark: { html },
    },
  },
}) => (
  <SectionWrapper spaceBelow={spaceBelow}>
    <Grid>
      <Row>
        <Col sm={12} md={9} mdOffset={3} lg={image ? 6 : 8} lgOffset={2}>
          <WYSIWYGContent>{ReactHtmlParser(html)}</WYSIWYGContent>
        </Col>
        {image && (
          <ImageCol sm={12} md={9} mdOffset={3} lg={4}>
            <Img fluid={image.fluid} alt={image.title} />
          </ImageCol>
        )}
      </Row>
    </Grid>
  </SectionWrapper>
);

export default Paragraph;

export const query = graphql`
  fragment Paragraph on ContentfulPageParagraph {
    id
    __typename
    image {
      title
      fluid(maxWidth: 400) {
        aspectRatio
        src
        srcSet
        sizes
      }
    }
    spaceBelow
    text {
      childMarkdownRemark {
        html
      }
    }
  }
`;
