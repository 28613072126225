import styled from 'styled-components';
import { Col, COLORS, LINE_HEIGHT, MEDIA, SPACING, TYPE_SCALE, Z_INDEX } from 'clark-styles';

import { FONT_WEIGHT } from '../../shared/typography';

const { CLARK_PRIMARY, CLARK_SECONDARY } = COLORS;
const { FW_MEDIUM, FW_SEMIBOLD } = FONT_WEIGHT;
const { COPY } = LINE_HEIGHT;
const { S_1, S_4 } = SPACING;
const { TS_3, TS_4 } = TYPE_SCALE;
const { Z_BOTTOM } = Z_INDEX;

export const StyledCol = styled(Col)`
  margin-bottom: ${S_4};
  ${MEDIA.small`
    margin-bottom: 0;
  `};
`;

export const ImageWrapper = styled.div`
  position: relative;

  &::before {
    background: url(${({ bg }) => bg}) center no-repeat;
    background-size: contain;
    content: '';
    height: 200%;
    position: absolute;
    top: -60%;
    width: 100%;
    ${({ leftColText }) => (leftColText ? 'left: 0' : 'right: 0')};
    ${Z_BOTTOM};
    ${MEDIA.medium`
      ${({ leftColText }) => (leftColText ? 'left: -12%' : 'right: -12%')};
      width: 124%;
    `};
  }
`;

export const Summary = styled.div`
  ${TS_4};
  ${COPY};

  a {
    color: ${CLARK_SECONDARY};
    ${FW_MEDIUM};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ol {
    counter-reset: li;

    li {
      counter-increment: li;
      display: flex;
      margin-top: ${S_1};

      &::before {
        color: ${CLARK_PRIMARY};
        content: counter(li) '. ';
        ${FW_SEMIBOLD};
        margin-right: ${S_1};
      }
    }
  }

  ul {
    li {
      display: flex;
      margin-top: ${S_1};

      &::before {
        color: ${CLARK_SECONDARY};
        content: '•';
        margin-right: ${S_1};
      }
    }
  }

  .large-text {
    ${MEDIA.large`
      ${TS_3};
    `};
  }
`;
