import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { graphql } from 'gatsby';
import { Grid, Row, Col } from 'clark-styles';

import { trackEvent } from '../../shared/analytics';

import Button from '../button';
import Icon from '../icon';
import SectionWrapper from '../section-wrapper';

import {
  AdditionalDetails,
  ColLeft,
  ColRight,
  Heading,
  PlanDetailsLarge,
  PlanDetailsSmall,
  PlanLink,
  Price,
  PriceJoiner,
  PriceReduced,
  PriceSeparator,
  PricingDetails,
  PricingOverview,
} from './styles';

const Pricing = ({
  data: {
    alternatePlanLeadIn,
    alternatePlanLevel,
    alternatePlanLink,
    additionalDetails,
    bgImage,
    ctaButtonLink,
    ctaButtonText,
    introOffer,
    introTerm,
    monthlyFee,
    planDetails: {
      childMarkdownRemark: { html: details },
    },
    planLevel,
    secondaryButtonLink,
    secondaryButtonText,
    sessionFee,
    spaceBelow,
    specialOffer,
  },
}) => (
  <SectionWrapper spaceBelow={spaceBelow}>
    <Grid>
      <Row>
        <Col sm={12} lg={10} lgOffset={1}>
          <p>You qualify for</p>
          <Heading>
            <span>Clark</span> {planLevel}
          </Heading>
        </Col>
      </Row>
      <Row>
        <ColLeft sm={12} md={6} lg={4} lgOffset={1}>
          <PlanDetailsLarge>{ReactHtmlParser(details)}</PlanDetailsLarge>
          {alternatePlanLeadIn && (
            <AdditionalDetails>
              <p>{alternatePlanLeadIn}</p>
              <PlanLink
                onClick={() => trackEvent('Alternate Plan Button Clicked', { alternatePlanLevel })}
                to={alternatePlanLink}
              >
                <span>Clark</span> {alternatePlanLevel}
                <Icon name="arrowRight" size={18} viewbox="0 0 18 18" />
              </PlanLink>
            </AdditionalDetails>
          )}
        </ColLeft>
        <ColRight sm={12} md={6} lg={6}>
          <PricingOverview bg={bgImage && bgImage.file.url}>
            <Grid>
              <Row>
                <Col sm={12} md={12} lg={10} lgOffset={1}>
                  <PricingDetails justifyContent="center">
                    {introOffer && (
                      <>
                        <p>
                          <Price>{introOffer}</Price> {introTerm}
                        </p>
                        <PriceSeparator />
                      </>
                    )}
                    <p>
                      {specialOffer !== null ? (
                        <PriceReduced>
                          <Price reduced>{monthlyFee}</Price>
                          <Price special>{specialOffer}</Price>
                        </PriceReduced>
                      ) : (
                        <Price>{monthlyFee}</Price>
                      )}
                      per month
                    </p>
                    {sessionFee && (
                      <>
                        <PriceJoiner>+</PriceJoiner>
                        <p>
                          <Price>{sessionFee}</Price> per session
                        </p>
                      </>
                    )}
                  </PricingDetails>
                  <Button
                    path={ctaButtonLink}
                    onClick={() => trackEvent('Pricing Button Clicked', { ctaButtonText })}
                  >
                    {ctaButtonText}
                  </Button>
                </Col>
              </Row>
            </Grid>
          </PricingOverview>
          <PlanDetailsSmall>{ReactHtmlParser(details)}</PlanDetailsSmall>
          {additionalDetails && (
            <Grid>
              <Row>
                <Col sm={12} md={12} lg={10} lgOffset={1}>
                  <AdditionalDetails>
                    {ReactHtmlParser(additionalDetails.childMarkdownRemark.html)}
                  </AdditionalDetails>
                </Col>
              </Row>
            </Grid>
          )}
          {secondaryButtonLink && (
            <Grid>
              <Row>
                <Col sm={12} md={12} lg={10} lgOffset={1}>
                  <Button
                    path={secondaryButtonLink}
                    onClick={() =>
                      trackEvent('Secondary Pricing Button Clicked', { secondaryButtonText })
                    }
                    styletype="tertiary"
                  >
                    {secondaryButtonText}
                  </Button>
                </Col>
              </Row>
            </Grid>
          )}
        </ColRight>
      </Row>
    </Grid>
  </SectionWrapper>
);

export default Pricing;

export const query = graphql`
  fragment Pricing on ContentfulPagePricing {
    id
    __typename
    additionalDetails {
      childMarkdownRemark {
        html
      }
    }
    alternatePlanLeadIn
    alternatePlanLevel
    alternatePlanLink
    bgImage {
      title
      file {
        url
      }
    }
    ctaButtonText
    ctaButtonLink
    introOffer
    introTerm
    monthlyFee
    planLevel
    planDetails {
      childMarkdownRemark {
        html
      }
    }
    secondaryButtonLink
    secondaryButtonText
    sessionFee
    spaceBelow
    specialOffer
  }
`;
