import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import { Flex } from 'clark-styles';
import { InputContainer, Error } from '../input/styles';
import { FormTextArea, CharacterCount } from './styles';

const renderField = ({ input, inputType, meta: { touched, error }, maxLength, ...rest }) => (
  <Fragment>
    {/* we rename the inputType prop to avoid a colision with the type attribute
    that is used to specify which form element to render */}
    <InputContainer showError={!(error && touched)}>
      <FormTextArea
        {...rest}
        {...input}
        showError={!(error && touched)}
        type={inputType}
        rows="5"
        maxLength={maxLength}
      />
    </InputContainer>
    <Flex justifyContent="space-between" alignItems="center">
      <div>{touched && (error && <Error>{error}</Error>)}</div>
      <CharacterCount>{maxLength && `${input.value.length}/${maxLength}`}</CharacterCount>
    </Flex>
  </Fragment>
);
const TextArea = ({ disabled, inputType, name, maxLength, ...rest }) => (
  <Field
    {...rest}
    component={renderField}
    disabled={disabled}
    inputType={inputType}
    name={name}
    maxLength={maxLength}
  />
);
export default TextArea;
