// @flow
/* eslint jsx-a11y/no-noninteractive-element-interactions: 0 */
import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import get from 'lodash/get';
import { localStorageGet, localStorageSet, formatCentsToDollars } from 'clark-utils';
import { BodyText, Flex, Button, H3Headline, BodySmall } from 'clark-styles';
import {
  MatchesContainer,
  OptionCard,
  DescriptorTitle,
  LargeIconContainer,
  IconContainer,
  BenefitsContainer,
  RateText,
  Container,
} from './styles';
import Icon from '../icon';
import OnlineTutoringIcon from '../../shared/assets/online_tutoring.svg';
import InPersonTutoringIcon from '../../shared/assets/in_person_tutoring.svg';
import LoadingMatch from './loading-match.gif';

import { PROSPECT_INFORMATION } from '../collection-form/constants';

export const MATCHED_OPTIONS = 'MATCHED_OPTIONS';

const getAndFormatCentsToDollars = (obj, key) => formatCentsToDollars(get(obj, key, null));

const Matches = ({ setHeader, setSubheader }) => {
  const [isMatching, setIsMatching] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [matchListings, setMatchListings] = useState({});
  useEffect(() => {
    setFormValues(localStorageGet(PROSPECT_INFORMATION));
    setMatchListings(localStorageGet(MATCHED_OPTIONS));
  }, []);

  const { tutoringLevel, City } = formValues;
  const { inPersonProfile, onlineProfile } = matchListings;

  const selectTutor = (slug, isOnlineTutoring) => {
    setHeader('Finding you a good match! Help is on the way.');
    setSubheader('');
    setIsMatching(true);
    localStorageSet(PROSPECT_INFORMATION, {
      ...formValues,
      tutoringFormat: isOnlineTutoring ? 'online' : 'in_person',
    });
    setTimeout(() => navigate(slug), 5000);
  };
  const onlineDescription =
    tutoringLevel && City ? `for ${tutoringLevel} level online tutoring.` : '';
  const inPersonDescription =
    tutoringLevel && City ? `for ${tutoringLevel} level in-person tutoring in ${City}.` : '';
  return isMatching ? (
    <img src={LoadingMatch} alt="Loading Match" />
  ) : (
    <MatchesContainer>
      <OptionCard sm={12} md={6} lg={6}>
        <DescriptorTitle>Meet Online</DescriptorTitle>
        <LargeIconContainer>
          <OnlineTutoringIcon />
        </LargeIconContainer>
        <BenefitsContainer>
          <Flex>
            <IconContainer>
              <Icon name="checkmark" />
            </IconContainer>
            <BodyText>More Convenient</BodyText>
          </Flex>
          <Flex style={{ color: 'red' }}>
            <IconContainer>
              <Icon name="checkmark" />
            </IconContainer>
            <BodyText>Less Expensive</BodyText>
          </Flex>
        </BenefitsContainer>
        <Flex alignItems="flex-end">
          <H3Headline>
            {getAndFormatCentsToDollars(onlineProfile, 'minimumHourlyRateInCents')}
          </H3Headline>
          <RateText>/hr</RateText>
        </Flex>
        <Container>
          <BodySmall>{onlineDescription}</BodySmall>
        </Container>

        <Button
          layout="fullWidth"
          onClick={() => selectTutor(`/tutors/${get(onlineProfile, 'slug', null)}`, true)}
        >
          Online
        </Button>
      </OptionCard>
      <OptionCard sm={12} md={6} lg={6}>
        <DescriptorTitle>In-person</DescriptorTitle>
        <LargeIconContainer>
          <InPersonTutoringIcon />
        </LargeIconContainer>
        <BenefitsContainer>
          <Flex>
            <IconContainer>
              <Icon name="checkmark" />
            </IconContainer>
            <BodyText>Face-to-face Connection</BodyText>
          </Flex>
        </BenefitsContainer>
        <Flex alignItems="flex-end">
          <H3Headline>
            {getAndFormatCentsToDollars(inPersonProfile, 'minimumHourlyRateInCents')}
          </H3Headline>
          <RateText>/hr</RateText>
        </Flex>
        <Container>
          <BodySmall>{inPersonDescription}</BodySmall>
        </Container>
        <Button
          layout="fullWidth"
          onClick={() => selectTutor(`/tutors/${get(inPersonProfile, 'slug', null)}`, false)}
        >
          In Person
        </Button>
      </OptionCard>
    </MatchesContainer>
  );
};

export default Matches;
