import styled from 'styled-components';
import { SPACING, COLORS } from 'clark-styles';

const { S_2 } = SPACING;
const { GREY_100 } = COLORS;

const SummaryText = styled.div`
  color: ${GREY_100};
  margin-bottom: ${S_2};
`;

export default SummaryText;
