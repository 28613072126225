import styled from 'styled-components';
import { Col, COLORS, LINE_HEIGHT, MEDIA, SPACING, TYPE_SCALE, Z_INDEX } from 'clark-styles';

const { GREY_3, GREY_10 } = COLORS;
const { COPY } = LINE_HEIGHT;
const { S_1, S_2, S_6 } = SPACING;
const { TS_3, TS_4 } = TYPE_SCALE;
const { Z_BOTTOM } = Z_INDEX;
const LogoImageHeight = '1.25rem';

export const Container = styled.div`
  &::before {
    background-image: linear-gradient(to top, ${GREY_3}, ${GREY_10});
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    ${Z_BOTTOM};
  }
`;

export const Summary = styled.p`
  ${TS_4};
  ${COPY};
  margin-bottom: ${S_2};
  ${MEDIA.large`
    ${TS_3};
    margin-bottom: ${S_6};
  `};
`;

export const ImageCol = styled(Col)`
  display: none;
  ${MEDIA.small`
    display: block;
  `};
`;

export const LogoGrid = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${S_1};
`;

export const LogoGridItem = styled.li`
  margin-bottom: ${S_2};
  width: 33.33%;

  &:nth-child(3n + 1) {
    text-align: left;
  }

  &:nth-child(3n + 2) {
    text-align: center;
  }

  &:nth-child(3n + 3) {
    text-align: right;
  }
`;

export const LogoGridImage = styled.img`
  max-height: ${LogoImageHeight};
  max-width: 100%;
  vertical-align: middle;
`;
