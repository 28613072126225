import React from 'react';
import { graphql } from 'gatsby';
import { Grid, Row, Col } from 'clark-styles';

import { trackConversion, trackEvent } from '../../shared/analytics';

import Button from '../button';
import HeadingElement from '../heading-element';
import SectionWrapper from '../section-wrapper';
import UtilityText from '../utility-text';

import { StyledCol, StyledRow } from './styles';

const Heading = ({
  data: { buttonLink, buttonText, eyebrow, fullWidth, lineBelow, spaceBelow, title },
}) => {
  const colSpanMd = fullWidth ? 12 : 6;
  const colSpanLg = fullWidth ? 12 : 8;
  return (
    <SectionWrapper spaceBelow={spaceBelow}>
      <Grid>
        {eyebrow && (
          <Row>
            <Col
              sm={12}
              md={buttonText ? 7 : colSpanMd}
              mdOffset={buttonText || !fullWidth ? 0 : 3}
              lg={buttonText ? 9 : colSpanLg}
              lgOffset={buttonText || fullWidth ? 0 : 2}
            >
              <UtilityText color="primary" hasMarginBottom>
                {eyebrow}
              </UtilityText>
            </Col>
          </Row>
        )}
        <StyledRow alignItems="center" lineBelow={lineBelow}>
          <Col
            sm={12}
            md={buttonText ? 7 : colSpanMd}
            mdOffset={buttonText ? 0 : 3}
            lg={buttonText ? 9 : colSpanLg}
            lgOffset={buttonText || fullWidth ? 0 : 2}
          >
            <HeadingElement element="h2" level={1} marginBottom="0">
              {title}
            </HeadingElement>
          </Col>
          {buttonText && (
            <StyledCol sm={12} md={5} lg={3}>
              <Button
                path={buttonLink}
                onClick={e => {
                  trackConversion(e);
                  trackEvent('Heading Link Clicked', {
                    buttonText,
                  });
                }}
                styletype="tertiary"
              >
                {buttonText}
              </Button>
            </StyledCol>
          )}
        </StyledRow>
      </Grid>
    </SectionWrapper>
  );
};

export default Heading;

export const query = graphql`
  fragment Heading on ContentfulPageHeading {
    id
    __typename
    buttonLink
    buttonText
    eyebrow
    fullWidth
    lineBelow
    spaceBelow
    title
  }
`;
