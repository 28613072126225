import styled, { css } from 'styled-components';
import {
  Col,
  Flex,
  BOX_SHADOW,
  COLORS,
  LETTER_SPACING,
  LINE_HEIGHT,
  MEDIA,
  SPACING,
  TYPE_SCALE,
  Z_INDEX,
} from 'clark-styles';

import { FONTS, FONT_WEIGHT } from '../../shared/typography';
import CHECKMARK from '../../shared/assets/checkmark.svg';

import Link from '../link';

const { BS_SMALL } = BOX_SHADOW;
const { CLARK_PRIMARY, CLARK_SECONDARY, GREY_50, GREY_100, WHITE } = COLORS;
const { SECONDARY_FONT } = FONTS;
const { FW_SEMIBOLD } = FONT_WEIGHT;
const { LS_2_5 } = LETTER_SPACING;
const { COPY, TITLE } = LINE_HEIGHT;
const { S_1, S_2, S_4 } = SPACING;
const { TS_1, TS_3, TS_4, TS_5, TS_6 } = TYPE_SCALE;
const { Z_BOTTOM } = Z_INDEX;

const LS_4 = css`
  letter-spacing: 4px;
`;

const H3 = css`
  ${TS_5};
  ${FW_SEMIBOLD};
  ${COPY};
  margin-bottom: ${S_1};
`;

export const AdditionalDetails = styled.div`
  ${TS_6};
  ${COPY};
  margin-top: ${S_4};

  h3 {
    ${H3};
  }

  p {
    margin-bottom: ${S_1};
  }

  ul {
    margin-bottom: ${S_1};

    li {
      display: flex;

      &::before {
        content: '•';
        margin-right: ${S_1};
      }
    }
  }
`;

export const ColLeft = styled(Col)`
  order: 2;
  ${MEDIA.small`
    order: 1;
  `};
`;

export const ColRight = styled(Col)`
  order: 1;
  ${MEDIA.small`
    order: 2;
  `};
`;

export const Heading = styled.h1`
  ${SECONDARY_FONT};
  ${TS_3};
  ${LS_4};
  ${TITLE};
  margin-bottom: ${S_2};
  margin-top: ${S_1};
  ${MEDIA.small`
    margin-bottom: ${S_4};
  `};

  span {
    color: ${CLARK_PRIMARY};
  }
`;

const PlanDetails = styled.div`
  h2 {
    ${TS_3};
    ${FW_SEMIBOLD};
    margin-bottom: ${S_1};
  }

  h3 {
    ${H3};
  }

  p {
    ${COPY};
    margin-bottom: ${S_2};
  }

  ul {
    &:not(:last-of-type) {
      margin-bottom: ${S_2};
    }

    li {
      display: flex;

      &:not(:last-child) {
        margin-bottom: ${S_1};
      }

      &::before {
        background-image: url(${CHECKMARK});
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        height: ${S_1};
        margin-right: ${S_1};
        width: ${S_1};
      }
    }
  }
`;

export const PlanDetailsLarge = styled(PlanDetails)`
  display: none;
  ${MEDIA.small`
    display: block;
  `};
`;

export const PlanDetailsSmall = styled(PlanDetails)`
  ${MEDIA.small`
    display: none;
  `};
`;

export const PlanLink = styled(Link)`
  color: ${GREY_100};
  ${SECONDARY_FONT};
  ${LS_2_5};
  text-decoration: none;

  span {
    color: ${CLARK_PRIMARY};
  }
`;

export const Price = styled.span`
  display: block;
  ${TS_1};
  ${TITLE};
  ${({ reduced }) =>
    reduced &&
    `
    color: ${GREY_50};
    position: relative;

    &::after {
      background-color: ${GREY_100};
      bottom: 0;
      content: '';
      height: 100%;
      left: 55%;
      position: absolute;
      transform: rotate(-60deg);
      width: 2px;
    }
  `}
  ${({ special }) =>
    special &&
    `
    color: ${CLARK_SECONDARY};
    margin-left: ${S_1};
  `}

  &::before {
    content: '$';
    ${TS_4};
    vertical-align: super;
  }
`;

export const PriceJoiner = styled.p`
  align-self: center;
  ${TS_4};
  margin: 0 ${S_2};
`;

export const PriceReduced = styled.span`
  display: flex;
`;

export const PriceSeparator = styled.span`
  align-self: flex-end;
  background-color: ${GREY_50};
  height: ${S_4};
  margin: 0 ${S_2};
  width: 1px;
`;

export const PricingDetails = styled(Flex)`
  ${SECONDARY_FONT};
  ${TS_6};
  ${LS_2_5};
  margin-bottom: ${S_2};
  text-align: center;
`;

export const PricingOverview = styled.div`
  background-color: ${WHITE};
  ${BS_SMALL};
  margin-bottom: ${S_2};
  padding-bottom: ${S_2};
  padding-top: ${S_2};
  ${MEDIA.small`
    margin-bottom: 0;
  `};
  ${MEDIA.large`
    position: relative;

    &::before {
      background: url(${({ bg }) => bg}) center no-repeat;
      background-size: contain;
      content: '';
      height: 200%;
      position: absolute;
      right: -25%;
      top: -50%;
      width: 100%;
      ${Z_BOTTOM};
    }
  `};
`;
