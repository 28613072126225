import React from 'react';

import { Support, SupportIcon, SupportPhoneNo } from './styles';

const SupportCTA = ({ className, supportHours, supportPhone }) => (
  <Support className={className}>
    <SupportIcon name="phone" />
    <p>
      <strong>
        Questions? Call us at{' '}
        <SupportPhoneNo href={`tel:${supportPhone}`}>{supportPhone}</SupportPhoneNo>
      </strong>
      <br />
      {supportHours}
    </p>
  </Support>
);

export default SupportCTA;
