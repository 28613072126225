import styled, { css } from 'styled-components';
import { Row, Col, BORDER_WIDTH, COLORS, MEDIA, SPACING } from 'clark-styles';

const { BW_1 } = BORDER_WIDTH;
const { GREY_25 } = COLORS;
const { S_1 } = SPACING;

export const StyledCol = styled(Col)`
  margin-top: ${S_1};
  ${MEDIA.small`
    margin-top: 0;
  `};
`;

export const StyledRow = styled(Row)`
  ${({ lineBelow }) =>
    lineBelow &&
    css`
      border-bottom: ${BW_1} solid ${GREY_25};
      padding-bottom: ${S_1};
    `}
`;
