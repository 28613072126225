import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

const Link = ({ children, to, ...other }) => {
  const internal = /^\/(?!\/)/.test(to);
  return (
    <>
      {internal ? (
        <GatsbyLink to={to} activeClassName="active" {...other}>
          {children}
        </GatsbyLink>
      ) : (
        <a href={to} {...other} target="_blank" rel="noreferrer noopener">
          {children}
        </a>
      )}
    </>
  );
};

export default Link;
