import styled, { css } from 'styled-components';
import { COLORS, LETTER_SPACING, SPACING, TYPE_SCALE } from 'clark-styles';

import { FONTS } from '../../shared/typography';

const { CLARK_PRIMARY, CLARK_SECONDARY, GREY_50, WHITE } = COLORS;
const { SECONDARY_FONT } = FONTS;
const { LS_2_5 } = LETTER_SPACING;
const { S_1 } = SPACING;
const { TS_6 } = TYPE_SCALE;

const primary = css`
  color: ${CLARK_PRIMARY};
`;

const secondary = css`
  color: ${CLARK_SECONDARY};
`;

const tertiary = css`
  color: ${GREY_50};
`;

const white = css`
  color: ${WHITE};
`;

const textColor = {
  primary: () => primary,
  secondary: () => secondary,
  tertiary: () => tertiary,
  white: () => white,
};

const StyledText = styled.p`
  ${({ color }) => textColor[color]};
  ${SECONDARY_FONT};
  ${TS_6};
  ${LS_2_5};
  ${({ hasMarginBottom }) => hasMarginBottom && `margin-bottom: ${S_1}`};
`;

export default StyledText;
