const initStripe = () => {
  if (typeof window !== 'undefined') {
    if (window.Stripe && process.env.GATSBY_APP_STRIPE_PUBLISHABLE_API_KEY) {
      return window.Stripe(process.env.GATSBY_APP_STRIPE_PUBLISHABLE_API_KEY);
    }
    throw new Error('no stripe');
  }
  return null;
};

export default initStripe;
