import React from 'react';
import { navigate } from 'gatsby';
import { localStorageSet } from 'clark-utils';
import moment from 'moment-timezone';
import { SubmissionError } from 'redux-form';
import {
  isRequired as requiredField,
  isMoney,
  isEmail,
  isPhoneNumber,
  length,
  normalizePhone,
} from '../../shared/validations';

import { trackEvent } from '../../shared/analytics';
import Input from '../input';
import Dropdown from '../dropdown';
import TextArea from '../textarea';
import RadioButtonGroup from '../radioButtonGroup';
import CheckboxGroup from '../checkboxGroup';
import CardElement from '../stripe-card-element';
import {
  postBuildApplicationFormSubmission,
  identifyLead,
  submitEvent,
  findMatches,
} from '../../shared/api';
import localData from '../../shared/localTutoringPrices.json';

import { EventTitlePrefixMap, PROSPECT_INFORMATION } from './constants';

import { FormStepButton } from './styles';

const ZIP_MAX_LENGTH = length(5);
const booleanValues = { true: true, false: false };

export const getInput = (
  trackingDescription,
  question,
  options,
  inputFieldType,
  placeholder,
  isRequired,
  propertyName,
  characterLimit,
  selectAnswer,
  incompleteFieldsCount,
  formType,
  stripe,
  save,
  disabledSubmit,
) => {
  let type;
  let inputType;
  let validate = isRequired ? [requiredField] : [];
  let values;
  let onChange;
  let normalize;

  const eventTitle = `${EventTitlePrefixMap[formType] || `${trackingDescription}:`} ${question}`;
  switch (inputFieldType) {
    case 'stripe':
      return stripe ? <CardElement /> : null;
    case 'radio':
      type = 'radioButton';
      values = options.map(({ label, value, isDate }) => {
        let val =
          booleanValues[value.toLowerCase()] != null ? booleanValues[value.toLowerCase()] : value;

        // have to set offset on utc so that Autopilot will read the date properly for emails (AL - 6/24/19)
        if (isDate && moment(value).isValid()) {
          // has be in specific format so that moment-timezone knows how to properly convert
          // specifically .tz('YYYY-MM-DD HH:MM', TIMEZONE) (AL - 7/19/19)
          const formattedValue = moment(value).format('YYYY-MM-DD');
          val = moment
            .tz(`${formattedValue} 18:00`, 'America/New_York')
            .utc()
            .format();
        }

        return { value: val, label };
      });
      onChange = (_, response) =>
        trackEvent(eventTitle, { question, response, incompleteFieldsCount });
      break;
    case 'links':
      return options.map(({ label, value, link }) => {
        const submit = () => {
          save(propertyName, value);
          selectAnswer(propertyName, value, link);
        };
        return (
          <FormStepButton
            key={value}
            path={disabledSubmit ? null : link}
            disabled={disabledSubmit}
            onClick={submit}
            styletype="tertiary"
          >
            {label}
          </FormStepButton>
        );
      });
    case 'textarea': {
      const data = {
        type: 'textarea',
        name: propertyName,
        placeholder,
        validate,
        onBlur: (_, response) =>
          trackEvent(eventTitle, { question, response, incompleteFieldsCount }),
        maxLength: characterLimit,
      };
      return <TextArea {...data} />;
    }
    case 'dropdown':
    case 'checkbox': {
      const formattedOptions = options.map(({ label, value, isDate }) => {
        let val =
          booleanValues[value.toLowerCase()] != null ? booleanValues[value.toLowerCase()] : value;

        // have to set offset on utc so that Autopilot will read the date properly for emails (AL - 6/24/19)
        if (isDate && moment(value).isValid()) {
          val = moment(value)
            .utcOffset('+05.00')
            .toISOString(true);
        }

        return { value: val, label };
      });
      const data = {
        type: inputFieldType === 'dropdown' ? 'dropdown' : 'checkboxGroup',
        name: propertyName,
        validate,
        options: formattedOptions,
        onChange: (_, response) =>
          trackEvent(eventTitle, { question, response, incompleteFieldsCount }),
      };
      return inputFieldType === 'dropdown' ? <Dropdown {...data} /> : <CheckboxGroup {...data} />;
    }

    case 'text':
      type = 'input';
      break;

    case 'number':
      type = 'input';
      inputType = 'number';
      break;

    case 'email':
      type = 'input';
      validate = validate.concat(isEmail);
      break;

    case 'phone number':
      type = 'input';
      normalize = normalizePhone;
      validate = validate.concat(isPhoneNumber);
      break;

    case 'money':
      type = 'input';
      inputType = 'number';
      validate = validate.concat(isMoney);
      break;

    case 'zip code':
      type = 'input';
      inputType = 'number';
      validate = validate.concat(ZIP_MAX_LENGTH);
      break;

    default:
      return null;
  }

  const data = {
    type,
    name: propertyName,
    placeholder,
    inputType,
    validate,
    values,
    onBlur: (_, response) => trackEvent(eventTitle, { question, response, incompleteFieldsCount }),
    onChange,
    normalize,
  };
  return type === 'radioButton' ? <RadioButtonGroup {...data} /> : <Input {...data} />;
};

export const submitSimpleTrackingForm = (formValues, trackingDescription) => {
  // not camel case because we want to use whatever variable in segment that is already used. (AL 6/19/19)
  // eslint-disable-next-line camelcase
  const { first_name: firstName, last_name: lastName, Email, phoneNumber } = formValues;

  identifyLead(firstName, lastName, Email, phoneNumber);
  submitEvent(`${trackingDescription} Submitted`, formValues);
};

export const progressForm = (formValues, eventDescription, dispatchSetFormValues) => {
  dispatchSetFormValues(formValues);
  submitEvent(eventDescription, formValues);
};

export const progressFindATutor = (formValues, setFormValuesAction, setLocationDataAction) => {
  let formattedFormValues = formValues;
  if (formValues.zipCode) {
    const data = localData.find(x => x.ZIPCODE.toString() === formValues.zipCode);
    setLocationDataAction(data);
    if (data) {
      formattedFormValues = { ...formValues, City: data.CITY };
    }
  }
  progressForm(formattedFormValues, 'Find a Tutor Form Part 1 Completed', setFormValuesAction);
};

export const formatFindATutorFormValues = collectedFormValues => {
  const {
    personBeingTutored,
    studentFirstName,
    studentLastName,
    first_name: firstName,
    last_name: lastName,
  } = collectedFormValues;
  const isForChild = personBeingTutored !== 'For me';
  if (isForChild) {
    const formattedValues = {
      ...collectedFormValues,
      first_name: studentFirstName,
      last_name: studentLastName,
      proxyFirstName: firstName,
      proxyLastName: lastName,
    };
    delete formattedValues.studentFirstName;
    delete formattedValues.studentLastName;

    return formattedValues;
  }
  return collectedFormValues;
};
export const submitFindATutorForm = async (collectedFormValues, setShowLoading) => {
  const formattedFormValues = formatFindATutorFormValues(collectedFormValues);

  localStorageSet(PROSPECT_INFORMATION, formattedFormValues);
  submitSimpleTrackingForm(formattedFormValues, 'Find a Tutor Form');
  const { subjectId, zipCode } = formattedFormValues;

  const matches = await findMatches(subjectId, zipCode);

  const responses = Object.values(matches).filter(Boolean);
  switch (responses.length) {
    case 0:
      trackEvent('No Match Found', formattedFormValues);
      return navigate('/contact-clark');
    case 1: {
      const { slug } = responses[0];
      const isOnlineTutoring = !!matches.onlineProfile;

      localStorageSet(PROSPECT_INFORMATION, {
        ...formattedFormValues,
        tutoringFormat: isOnlineTutoring ? 'online' : 'in_person',
      });
      setShowLoading(true);
      return setTimeout(() => navigate(`/tutors/${slug}`), 3000);
    }
    case 2:
      return navigate('/matching-options');
    default:
      return navigate('/contact-clark');
  }
};

export const submitConsultationForm = (formValues, confirm, dispatchErrorMessage) => {
  submitSimpleTrackingForm(formValues, 'Book a Consultation Form');
  const formattedData = {
    Email: formValues.tutorEmail,
    studentFirstName: formValues.first_name,
    studentLastName: formValues.last_name,
    studentPhoneNumber: formValues.phoneNumber,
    studentEmail: formValues.Email,
    studentNote: formValues.studentNote,
  };
  if (formValues.tutorId) {
    submitEvent(`Consultation Requested`, formattedData, formValues.tutorId);
    confirm();
  } else {
    dispatchErrorMessage('Sorry, there was an issue submitting this form. Please contact support.');
  }
};

export const submitBuildApplicationForm = async (
  formValues,
  stripe,
  dispatchErrorMessage,
  dispatchSetIsLoadingToTrue,
  dispatchSetIsLoadingToFalse,
  dispatchClearFormValues,
) => {
  dispatchSetIsLoadingToTrue();

  const { nameOnCard } = formValues;
  const stripeResponse = await stripe.createToken({ name: nameOnCard });
  if (stripeResponse.error) {
    const { message } = stripeResponse.error;
    dispatchErrorMessage(message);
    dispatchSetIsLoadingToFalse();
    throw new SubmissionError({
      _error: message,
    });
  }
  const {
    token: { id: token },
  } = stripeResponse;
  try {
    const success = await postBuildApplicationFormSubmission(
      formValues,
      token,
      dispatchErrorMessage,
    );
    if (success) {
      navigate('/build-application-submitted/');
      dispatchClearFormValues();
    }
  } finally {
    dispatchSetIsLoadingToFalse();
  }
};
