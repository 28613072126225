import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Grid, Row } from 'clark-styles';

import SectionWrapper from '../section-wrapper';

import { StyledCol, Summary } from './styles';

const PhotoGrid = ({ data: { items, spaceBelow } }) => (
  <SectionWrapper spaceBelow={spaceBelow}>
    <Grid>
      <Row>
        {items.map(
          ({
            backgroundColor,
            id,
            image: { fluid, title: altName },
            photoGridSummary: {
              childMarkdownRemark: { html },
            },
          }) => (
            <StyledCol key={id} sm={6} md={3} lg={3}>
              <Img fluid={fluid} alt={altName} />
              <Summary backgroundColor={backgroundColor}>{ReactHtmlParser(html)}</Summary>
            </StyledCol>
          ),
        )}
      </Row>
    </Grid>
  </SectionWrapper>
);

export default PhotoGrid;

export const query = graphql`
  fragment PhotoGrid on ContentfulPagePhotoGrid {
    id
    __typename
    items {
      backgroundColor
      id
      image {
        fluid {
          aspectRatio
          sizes
          src
          srcSet
        }
        title
      }
      photoGridSummary: summary {
        childMarkdownRemark {
          html
        }
      }
    }
    spaceBelow
  }
`;
