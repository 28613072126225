import styled, { css } from 'styled-components';
import { Grid, Row, Col, COLORS, LINE_HEIGHT, MEDIA, SPACING, TRANSITIONS } from 'clark-styles';

import Link from '../link';

const { CLARK_SECONDARY, WHITE } = COLORS;
const { COPY } = LINE_HEIGHT;
const { S_05, S_1, S_2 } = SPACING;
const { EASE_IN_OUT_25 } = TRANSITIONS;

export const StyledGrid = styled(Grid)`
  margin-bottom: calc(${S_05} + ${S_1});
  ${MEDIA.small`
    margin-bottom: ${S_05};
  `};
`;

export const StyledRow = styled(Row)`
  background-color: ${WHITE};
  overflow: hidden;
  position: relative;
  ${({ link }) =>
    link &&
    css`
      &::after {
        background-color: ${CLARK_SECONDARY};
        content: '';
        height: 100%;
        opacity: 0;
        position: absolute;
        right: 0;
        ${EASE_IN_OUT_25};
        width: ${S_05};
      }

      &:hover {
        &::after {
          opacity: 1;
        }
      }
    `}
`;

export const PaddedCol = styled(Col)`
  padding: ${S_1} calc(${S_05} + ${S_1}) ${S_2};
  ${MEDIA.small`
    padding: ${S_1} 0;
  `};
  ${MEDIA.large`
    padding: ${S_2} 0;
  `};
`;

export const ShortSummary = styled.p`
  ${COPY};
  margin-bottom: ${S_1};
  ${MEDIA.medium`
    display: none;
  `};
`;

export const LongSummary = styled.p`
  display: none;
  ${MEDIA.medium`
    display: block;
    ${COPY};
    margin-bottom: ${S_05};
  `};
`;

export const SummaryLink = styled(Link)`
  color: ${CLARK_SECONDARY};
  text-decoration: none;
`;
