import { localStorageSet } from 'clark-utils';

import { getUserId, trackUser } from './analytics';
import { MATCHED_OPTIONS } from '../components/matches';

const apiBases = {
  production: 'https://api.hiclark.com',
  development: 'http://localhost:3000',
  qa: 'https://api.qa.hiclark.com',
  demo: 'https://api-demo.hiclark.net',
};

export const url = apiBases[process.env.NODE_ENV] || 'https://manager-qa.hiclark.com';

export const postBuildApplicationFormSubmission = async (
  formValues,
  stripeToken,
  dispatchErrorMessage,
) => {
  const userId = getUserId();
  const response = await fetch(`${url}/submit-build-application`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ formValues, stripeToken, userId }),
  });
  const hasError = response.status === 400;
  if (hasError) {
    const json = await response.json();
    const error = json.errors[0];
    const { detail: message } = error;
    dispatchErrorMessage(message);
  }
  return !hasError;
};

export const submitEvent = async (eventName, formValues, id = null) => {
  const userId = id || getUserId();
  const response = await fetch(`${url}/submit-event`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ formValues, eventName, userId }),
  });
  const hasError = response.status === 500;
  return !hasError;
};

export const identifyLead = async (firstName, lastName, email, phone) => {
  const userId = getUserId();
  trackUser(firstName, lastName, email, phone);
  const response = await fetch(`${url}/identify-lead`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ firstName, lastName, email, phone, userId }),
  });
  const hasError = response.status === 500;
  return !hasError;
};

export const fetchMasterSubjects = async () => {
  let data;
  try {
    const endpoint = `${url}/public/all-tutoring-subjects`;
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });

    data = await response.json();
  } catch (err) {
    console.log(err);
    return [];
  }

  return data;
};

export const findMatches = async (subjectId, zipCode) => {
  try {
    const response = await fetch(
      `${url}/public/matching?subjectIds=[${subjectId}]&zipCode=${zipCode}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    );
    const data = await response.json();
    localStorageSet(MATCHED_OPTIONS, data);
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
