import styled, { css } from 'styled-components';
import {
  BOX_SHADOW,
  COLORS,
  LETTER_SPACING,
  MEDIA,
  SPACING,
  TRANSITIONS,
  TYPE_SCALE,
  Z_INDEX,
} from 'clark-styles';

import { FONTS } from '../../shared/typography';

import Button from '../button';
import Link from '../link';
import SupportCTA from '../support-cta';

const { BS_SMALL } = BOX_SHADOW;
const { CLARK_PRIMARY, GREY_100, WHITE } = COLORS;
const { SECONDARY_FONT } = FONTS;
const { LS_2_5 } = LETTER_SPACING;
const { S_05, S_1, S_2, S_4 } = SPACING;
const { EASE_IN_OUT_25 } = TRANSITIONS;
const { TS_6 } = TYPE_SCALE;
const { Z_BOTTOM, Z_TOP } = Z_INDEX;

const menuButtonLine = css`
  background-color: ${GREY_100};
  height: 2px;
  transition: all 0.2s ease;
  width: ${S_1};
`;

export const HeaderElement = styled.header`
  background-color: ${WHITE};
  ${BS_SMALL};
  left: 0;
  max-height: 0;
  position: ${({ scrolled }) => (scrolled ? 'fixed' : 'absolute')};
  top: 0;
  width: 100%;
  ${Z_TOP};
  ${MEDIA.medium`
    background-color: ${({ showBg }) => (showBg ? WHITE : 'transparent')};
    ${({ showBg }) => (showBg ? BS_SMALL : 'box-shadow: none')};
  `};
  ${({ scrolled }) =>
    scrolled &&
    `
    	max-height: ${S_4};
      overflow-y: hidden;
    	${EASE_IN_OUT_25};
  `};
`;

export const HeaderCTA = styled(Link)`
  color: ${CLARK_PRIMARY};
  ${SECONDARY_FONT};
  ${TS_6};
  ${LS_2_5};
  margin-left: auto;
  text-decoration: none;
  ${MEDIA.medium`
    display: none;
  `};
`;

export const HeaderButton = styled(Button)`
  display: none;
  ${MEDIA.medium`
    display: block;
    margin-left: ${S_2};
    padding: ${S_1} 0;
    text-align: center;
    width: 20%;
  `};
`;

export const HeaderLogo = styled.p`
  height: 20px;
  width: 115px;
  ${MEDIA.large`
    height: 24px;
    width: 138px;
  `};
`;

export const Nav = styled.nav`
  ${MEDIA.medium`
    margin-left: auto;
  `};
`;

export const MenuButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: 0;
  display: flex;
  flex-direction: column;
  height: ${S_1};
  justify-content: center;
  margin-left: ${S_2};
  outline: 0;
  padding: 0;
  width: ${S_1};
  ${MEDIA.medium`
    display: none;
  `};
`;

export const MenuButtonTop = styled.div`
  ${menuButtonLine};
  margin-bottom: 3px;
  transform: ${({ isMenuOpen }) => (isMenuOpen ? 'translateY(-1px) rotate(45deg)' : 'none')};
  transform-origin: top left;
`;

export const MenuButtonMiddle = styled.div`
  ${menuButtonLine};
  opacity: ${({ isMenuOpen }) => (isMenuOpen ? '0' : '1')};
`;

export const MenuButtonBottom = styled.div`
  ${menuButtonLine};
  margin-top: 3px;
  transform: ${({ isMenuOpen }) => (isMenuOpen ? 'translateX(-1px) rotate(-45deg)' : 'none')};
  transform-origin: top left;
`;

export const Menu = styled.div`
  background-color: ${WHITE};
  ${BS_SMALL};
  display: ${({ isMenuOpen }) => (isMenuOpen ? 'block' : 'none')};
  left: 0;
  padding-left: calc(${S_05} + ${S_1});
  padding-right: calc(${S_05} + ${S_1});
  position: absolute;
  text-align: right;
  top: 100%;
  width: 100%;
  ${Z_TOP};
  ${MEDIA.medium`
    background-color: transparent;
    box-shadow: none;
    display: flex;
    padding: 0;
    position: relative;
    text-align: center;
    top: 0;
  `};
`;

export const MenuScreen = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  content: '';
  display: ${({ isMenuOpen }) => (isMenuOpen ? 'block' : 'none')};
  height: 100%;
  left: 0;
  position: fixed;
  top: ${S_4};
  width: 100%;
  ${Z_BOTTOM};
  ${MEDIA.medium`
    display: none;
  `};
`;

export const MenuItems = styled.ul`
  ${MEDIA.medium`
    display: ${({ scrolled }) => (scrolled ? 'none' : 'block')};
  `};
`;

export const MenuItem = styled.li`
  margin: ${S_2} 0;
  ${MEDIA.medium`
    display: inline-block;
    margin: 0 0 0 ${S_2};
  `};
`;

export const MenuItemLink = styled(Link)`
  color: ${GREY_100};
  ${SECONDARY_FONT};
  ${TS_6};
  ${LS_2_5};
  text-decoration: none;
`;

export const MenuSupportCTA = styled(SupportCTA)`
  ${TS_6};
  justify-content: flex-end;
  text-align: right;
  ${MEDIA.medium`
    display: ${({ scrolled }) => (scrolled ? 'flex' : 'none')};
    margin: 0 ${S_2} 0 0;
    text-align: left;
  `};
`;

export const MenuSupportImage = styled.img`
  margin-right: calc(${S_1} + ${S_05});
  max-height: calc(${S_2} + ${S_05});
`;

export const FormSupportCTA = styled(SupportCTA)`
  display: none;
  ${MEDIA.medium`
    display: flex;
    margin: 0;
  `};
`;
