/* eslint-disable react/prefer-stateless-function */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { CARD_ELEMENT_OPTIONS, CollectCardElement, Label, CardInfoContainer } from './styles';
import { Error } from '../input/styles';
import Input from '../input';
import { isRequired } from '../../shared/validations';
import { setIsStripeComplete, setErrorMessage } from '../../state/actions';

const data = {
  type: 'input',
  name: 'nameOnCard',
  validate: isRequired,
};

const mapStateToProps = state => {
  const {
    state: { errorMessage },
  } = state;
  return { errorMessage };
};

const mapDispatchToProps = dispatch => ({
  dispatchSetIsStripeComplete: isComplete => dispatch(setIsStripeComplete(isComplete)),
  dispatchSetErrorMessage: message => dispatch(setErrorMessage(message)),
});
const CardElement = props => {
  const { errorMessage, dispatchSetIsStripeComplete, dispatchSetErrorMessage } = props;

  const handleChange = ({ error, complete }) => {
    dispatchSetErrorMessage(error ? error.message : null);
    dispatchSetIsStripeComplete(complete);
  };

  return (
    <Fragment>
      <Label>Name on Card</Label>
      <Input {...data} />
      <CardInfoContainer>
        <Label>Card Information</Label>
        <CollectCardElement
          {...CARD_ELEMENT_OPTIONS}
          onChange={handleChange}
          showError={errorMessage}
        />
        <div>{errorMessage && <Error>{errorMessage}</Error>}</div>
      </CardInfoContainer>
    </Fragment>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CardElement);
