import styled, { css } from 'styled-components';
import { Row, Col, COLORS, LINE_HEIGHT, MEDIA, SPACING, TYPE_SCALE, Z_INDEX } from 'clark-styles';

import { FONT_WEIGHT } from '../../shared/typography';

import Link from '../link';

const { CLARK_SECONDARY, GREY_100 } = COLORS;
const { FW_SEMIBOLD } = FONT_WEIGHT;
const { COPY } = LINE_HEIGHT;
const { S_2, S_4 } = SPACING;
const { TS_4 } = TYPE_SCALE;
const { Z_3 } = Z_INDEX;

export const StickyWrapper = styled.div`
  ${({ sticky }) =>
    sticky &&
    css`
      position: fixed;
      top: ${S_4};
      width: 100%;
      ${Z_3};
    `};
`;

export const StyledCol = styled(Col)`
  ${TS_4};
  ${COPY};
  position: absolute;
  top: ${S_2};
`;

export const StyledLink = styled(Link)`
  color: ${GREY_100};
  text-decoration: none;

  &.active {
    color: ${CLARK_SECONDARY};
    ${FW_SEMIBOLD};
  }
`;

export const StyledRow = styled(Row)`
  display: none;
  ${MEDIA.small`
    display: block;
    position: relative;
  `};
`;
