import styled from 'styled-components';
import {
  Grid,
  Row,
  Col,
  BOX_SHADOW,
  COLORS,
  LINE_HEIGHT,
  MEDIA,
  SPACING,
  TYPE_SCALE,
} from 'clark-styles';

import { FONT_STYLE } from '../../shared/typography';

import UtilityText from '../utility-text';

const { BS_LARGE } = BOX_SHADOW;
const { WHITE } = COLORS;
const { FS_ITALIC } = FONT_STYLE;
const { COPY } = LINE_HEIGHT;
const { S_2, S_4, S_6 } = SPACING;
const { TS_3 } = TYPE_SCALE;

export const StyledGrid = styled(Grid)`
  ${({ alignRight }) => (alignRight ? 'padding-left: 0' : 'padding-right: 0')};
  ${MEDIA.small`
    ${({ alignRight }) => (alignRight ? 'padding-left: 0' : 'padding-right: 0')};
  `};
  ${MEDIA.medium`
    ${({ alignRight }) => (alignRight ? 'padding-left: 0' : 'padding-right: 0')};
  `};
`;

export const StyledRow = styled(Row)`
  ${({ alignRight }) => (alignRight ? 'margin-left: 0' : 'margin-right: 0')};
  position: relative;
`;

export const PositionedCols = styled(Col)`
  position: absolute;
  width: 100%;
  ${MEDIA.small`
    height: 100%;
    ${({ alignRight }) => (alignRight ? 'right: 0' : 'left: 0')};
    top: ${S_6};
  `};
  ${MEDIA.medium`
    ${({ alignRight }) => (alignRight ? 'right: auto' : 'left: -1.5rem')};
  `};
`;

export const ImageSmall = styled.div`
  ${BS_LARGE};
  left: ${({ alignRight }) => (alignRight ? '50%' : 0)};
  max-width: 10rem;
  position: absolute;
  width: 50%;
  ${MEDIA.small`
    display: none;
  `};
`;

export const ImageLarge = styled.div`
  display: none;
  ${MEDIA.small`
    ${BS_LARGE};
    display: block;
  `};
`;

export const QuoteGrid = styled(Grid)`
  background-color: #00b3ad;
  padding-bottom: ${S_2};
  padding-top: ${S_2};
  ${MEDIA.small`
    padding-bottom: ${S_4};
    padding-top: ${S_4};
  `};
`;

export const Quote = styled.blockquote`
  color: ${WHITE};
  ${FS_ITALIC};
  ${COPY};
  margin-bottom: ${S_2};
  ${MEDIA.small`
    ${TS_3};
  `};
`;

export const Cite = styled(UtilityText)`
  ${COPY};
  text-align: right;
`;
