import React from 'react';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
import { setFormValues } from '../state/actions';

import Blockquote from '../components/blockquote';
import CollectionForm from '../components/collection-form';
import CTA from '../components/cta';
import Event from '../components/event';
import FeatureGrid from '../components/feature-grid';
import FeatureHighlight from '../components/feature-highlight';
import Heading from '../components/heading';
import Hero from '../components/hero';
import IconTextLockup from '../components/icon-text-lockup';
import Image from '../components/image';
import Layout from '../components/layout';
import Nav from '../components/nav';
import Paragraph from '../components/paragraph';
import PhotoGrid from '../components/photo-grid';
import Pricing from '../components/pricing';
import Summary from '../components/summary';

const mapDispatchToProps = dispatch => ({
  dispatchSetTutorInfo: (id, email) => dispatch(setFormValues({ tutorId: id, tutorEmail: email })),
});

const IndexPage = ({
  data: {
    contentfulPage: {
      pageModules,
      showFooter,
      showSupportImage,
      spaceBelowHeader,
      tutorEmail,
      tutorId,
      ...pageProps
    },
    contentfulGlobalElements,
    site: {
      siteMetadata: { social },
    },
  },
  location,
  dispatchSetTutorInfo,
}) => {
  // you can blame michael for this
  // MP 2019.08.30
  if (typeof window !== 'undefined' && window.location.href.match(/herokuapp/i)) {
    const url = new URL(window.location.href);
    url.hostname = 'www.hiclark.com';
    window.location = url.href;
  }

  if (tutorId && tutorEmail) {
    dispatchSetTutorInfo(tutorId, tutorEmail);
  }
  return (
    <Layout
      fullWrapper={showFooter}
      globalElements={contentfulGlobalElements}
      pageMeta={pageProps}
      location={location}
      social={social}
      spaceBelowHeader={spaceBelowHeader}
      showSupportImage
    >
      {pageModules &&
        pageModules.map(({ id, __typename: type, ...moduleProps }) => {
          const module = {
            ContentfulNavigation: <Nav key={id} data={moduleProps} />,
            ContentfulPageCollectionForm: (
              <CollectionForm key={id} data={moduleProps} location={location} />
            ),
            ContentfulPageCta: <CTA key={id} data={moduleProps} />,
            ContentfulPageEvent: <Event key={id} data={moduleProps} />,
            ContentfulPageFeatureGrid: <FeatureGrid key={id} data={moduleProps} />,
            ContentfulPageFeatureHighlight: <FeatureHighlight key={id} data={moduleProps} />,
            ContentfulPageHeading: <Heading key={id} data={moduleProps} />,
            ContentfulPageHero: <Hero key={id} data={moduleProps} location={location} />,
            ContentfulPageIconTextLockup: <IconTextLockup key={id} data={moduleProps} />,
            ContentfulPageImage: <Image key={id} data={moduleProps} />,
            ContentfulPageParagraph: <Paragraph key={id} data={moduleProps} />,
            ContentfulPagePhotoGrid: <PhotoGrid key={id} data={moduleProps} />,
            ContentfulPagePricing: <Pricing key={id} data={moduleProps} />,
            ContentfulPageSummary: <Summary key={id} data={moduleProps} />,
            ContentfulPageTestimonial: <Blockquote key={id} data={moduleProps} />,
          };
          return module[type];
        })}
    </Layout>
  );
};
export default connect(
  null,
  mapDispatchToProps,
)(IndexPage);

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      id
      title
      slug
      metaDescription
      metaImage {
        id
        file {
          url
        }
      }
      metaTitle
      pageModules {
        ... on ContentfulNavigation {
          ...Navigation
        }
        ... on ContentfulPageCollectionForm {
          ...CollectionForm
        }
        ... on ContentfulPageCta {
          ...CTA
        }
        ... on ContentfulPageEvent {
          ...Event
        }
        ... on ContentfulPageFeatureGrid {
          ...FeatureGrid
        }
        ... on ContentfulPageFeatureHighlight {
          ...FeatureHighlight
        }
        ... on ContentfulPageHeading {
          ...Heading
        }
        ... on ContentfulPageHero {
          ...Hero
        }
        ... on ContentfulPageIconTextLockup {
          ...IconTextLockup
        }
        ... on ContentfulPageImage {
          ...Image
        }
        ... on ContentfulPageParagraph {
          ...Paragraph
        }
        ... on ContentfulPagePhotoGrid {
          ...PhotoGrid
        }
        ... on ContentfulPagePricing {
          ...Pricing
        }
        ... on ContentfulPageSummary {
          ...Summary
        }
        ... on ContentfulPageTestimonial {
          ...Testimonial
        }
      }
      showFooter
      tutorId
      tutorEmail
      spaceBelowHeader
      showSupportImage
      supportPhoneNumber
      topNavButtonText
      topNavButtonLink
      topNavMobileCTA
      topNavMobileCTALink
    }
    contentfulGlobalElements {
      ...GlobalElements
    }
    site {
      siteMetadata {
        social {
          name
          url
        }
      }
    }
  }
`;
